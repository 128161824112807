import {
    Box,
    Text,
    VStack,
    Link,
    Center,
    Icon,
    useColorModeValue,
    Header,
} from '@chakra-ui/react';
import Navbar from '../navbar';
import Footer from '../components/footer';

const CircleIcon = (props) => (
    <Icon viewBox="0 0 200 200" {...props}>
        <path
            fill="currentColor"
            d="M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0"
        />
    </Icon>
)

export default function terms() {
    return (

        <Box>
            <Navbar />
            <TermsHeader />
            <Terms />
            <Footer />
        </Box>
    )
}

const TermsHeader = () => {
    return (
        /* Terms and Conditions - last updated... */
        <VStack
            bg={useColorModeValue('white', 'gray.800')}
        >
            <Text
                pt={{ base: 40, md: 60 }}
                pb={20} fontSize={"4xl"}
                align="center">
                Terms {'&'} Conditions
            </Text>
            <Text pt="98px" pb={6} fontStyle="italic">
                Last updated on April 3, 2021
            </Text>
        </VStack>
    );
}

const Terms = () => {
    return (
        <Center paddingBottom="280px" bg='white' color={'gray.900'}>
            <Box px={{ base: 8, md: 16 }} pb={{ base: 20, md: 40 }}>

                <Box maxW="4xl">
                    {/* PREFACE*/}
                    <Text pt={8} pb={4} fontWeight="medium" fontSize="16px"
                    >
                        Preface
                    </Text>

                    <Box>
                        {/* paragraph section */}
                        <Box color="gray" fontSize="16px">
                            <Text paddingBottom="24px" >
                                Yintran Group Holdings Limited (the “Company”), pursuant to section 8F(2) of the Payment Systems and Stored Value Facilities Ordinance (the “Ordinance”), with Stored Value Facilities (“SVF”) License No. SVF0014 granted by the Hong Kong Monetary Authority (“HKMA”), offers a payment transfer platform (the “Platform”) services that allows users to make and receive payments by using a device with an internet connection (the “Services”). All money transfers among users are channeled through licensed banks in Hong Kong based on users’ instructions via devices with an internet connection and through the Platform. All users’ money in the Platform is stored in and under the custody of a licensed bank in Hong Kong.
                            </Text>

                            <Text paddingBottom="24px" >
                                The terms and conditions of an agreement (the “Agreement”) between any one of users and the Company apply to the user’s use of the Company’s Platform and Services, including but not limited to the user’s account (“eWallet” in the Platform) where the user has access thereto to make payment transfer. Once the user, following account setup steps stated in the Company’s website, or downloading the Company’s App from the Google Play Store or Apple App Store, sets up an account, the user understands that all the terms and conditions in this Agreement have been read, understood and agreed/accepted (including the Company’s Privacy Policy which shall form part of the Agreement) and be bound by the terms and conditions of the Agreement. If the user disagrees with the terms and conditions of the Agreement and refuses to be bound thereby, the user shall deregister the account with the Company and immediately cease using the Platform and Services.
                            </Text>

                            <Text paddingBottom="24px" >
                                The Company reserves the right to amend and update the terms and conditions of this Agreement, from time to time and at any time, by posting amendments in its website and App. All amended terms and conditions shall be deemed effective in seven (7) Days from the amendments being published on the Company’s website and App. By continuing to use the Services, the user is deemed to have agreed and accepted the amendments. The Company also reserves the right via email or SMS to post amendments to users where the amendments shall be deemed effective in seven (7) Days from the day the amendments are sent.
                            </Text>
                        </Box>

                        {/* 1.     YINTRAN PAYMENT TRANSFER SERVICES*/}
                        <Box paddingTop="32px" paddingBottom="16px">
                            <Text fontWeight="medium" fontSize="16px">
                                1. Yintran Payment Transfer Services
                            </Text>
                        </Box>

                        {/* 1.1 General*/}
                        <Box paddingTop="8px" paddingBottom="16px">
                            <Text fontSize="16px" >
                                1.1 General
                            </Text>
                        </Box>
                        {/* paragraph section */}
                        <Box color="gray" fontSize="16px">
                            <Text paddingBottom="24px" >
                            The Company’s Platform provides the user convenience to transfer payment to, request payment from, and receive payment from any one of other Platform’s users via internet connected devices such as computer, mobile phone, tablet, etc. Add Friend, Payment QR, Request QR, ID QR, Transfer, Refill (Top up), Cash Out (Redemption RMB, HKD), Summary, Notifications, Invitation Code, Request Payment, and Link to Bank Account are the main features in the Platform to facilitate users to transfer payment among all users.
                            </Text>
                        </Box>

                        {/* 1.2     TOP UP (REFILL) & REDEMPTION (CASH OUT) - alt: Adding Money (or "topping up") and Cashing Out*/}
                        <Box paddingTop="8px" paddingBottom="16px">
                            <Text fontSize="16px" >
                                1.2 Top Up (Refill) and Redemption (Cash Out)
                            </Text>
                        </Box>
                        {/* paragraph section */}
                        <Box color="gray" fontSize="16px">
                            <Text paddingBottom="24px" >
                            The user may top up (refill) his/her/its eWallet in the Platform from his/her/its own Linked Bank Account or other bank or SVF accounts, or redeem (cash out) the stored value in his/her/its eWallet to his/her/its Linked Bank Account or other bank/SVF accounts.
                            </Text>
                        </Box>

                        {/* 1.3     BIOMETRIC AUTHENTICATION*/}
                        <Box paddingTop="8px" paddingBottom="16px">
                            <Text fontSize="16px">
                                1.3 Biometric Authentication
                            </Text>
                        </Box>
                        {/* paragraph section */}
                        <Box color="gray" fontSize="16px">
                            <Text paddingBottom="24px" >
                            If the user chooses to log in his/her/its eWallet or to make payment by using the biometric authentication, to enhance the payment transfer security, the user is required to designate ONLY one fingerprint or facial display at his/her/its account with the mobile device. The user utilizes the biometric authentication at his/her/its own risk, and the user indemnifies the Company of any loss caused by the usage of the biometric authentication process.
                            </Text>
                        </Box>

                        {/* 1.4     RECIPIENT OF PAYMENT*/}
                        <Box>
                            <Box paddingTop="8px" paddingBottom="16px">
                                <Text fontSize="16px">
                                    1.4 Receipt of Payment
                                </Text>
                            </Box>
                            {/* paragraph */}
                            <Box color="gray" fontSize="16px">
                                <Text paddingBottom="24px" >
                                If the recipient is not the 5D Pay user, the user may obtain either FPS ID or bank name, bank account name and bank account number to make payment transfer to the recipient. If the recipient is a 5D Pay user, the user can directly make payment via the Platform to the recipient either by direct transfer or by scanning recipient’s QR ID.
                                </Text>
                            </Box>
                        </Box>

                        {/* 1.5     NO DEPOSIT PROTECTION COVERAGE*/}
                        <Box>
                            <Box paddingTop="8px" paddingBottom="16px">
                                <Text fontSize="16px">
                                    1.5 No Deposit Protection Coverage
                                </Text>
                            </Box>
                            {/* paragraph */}
                            <Box color="gray" fontSize="16px">
                                <Text paddingBottom="24px" >
                                The user is fully aware that the Company, other than its being a SVF licensee pursuant to the Ordinance, is NOT a bank. The SVF balances are NOT protected by the Hong Kong Deposit Protection Scheme or any other guarantee.
                                </Text>
                            </Box>
                        </Box>

                        {/* 1.6     NO INTEREST*/}
                        <Box>
                            <Box paddingTop="8px" paddingBottom="16px">
                                <Text fontSize="16px">
                                    1.6 No Interest
                                </Text>
                            </Box>
                            {/* paragraph */}
                            <Box color="gray" fontSize="16px">
                                <Text paddingBottom="24px" >
                                The user agrees that the user's eWallet will not earn or gain any interest on the money or the stored value in the user's eWallet. However, in consideration of usage of the Services, the user hereby agrees to irrevocably transfer and assign to the Company any right that the user may have in any interests that may accrue on money held in the user's eWallet which is pooled with other users' stored value in a bank account under custody. This assignment applies only to interest earned on the user's stored value, and nothing in this Agreement grants the Company any right to claim ownership of the user's stored value maintained with the Platform.
                                </Text>
                            </Box>
                        </Box>


                        {/* 2.     REGISTRATION & ELIGIBILITY*/}
                        <Box paddingTop="32px" paddingBottom="16px">
                            <Text fontWeight="medium" fontSize="16px">
                                2. Registration and Eligibility
                            </Text>
                        </Box>

                        {/* 2.1     WEBSITE AND APP DOWNLOAD */}
                        <Box>
                            <Box paddingTop="8px" paddingBottom="16px">
                                <Text fontSize="16px">
                                    2.1 Website and App Download
                                </Text>
                            </Box>
                            {/* paragraph */}
                            <Box color="gray" fontSize="16px">
                                <Text paddingBottom="24px" >
                                    Individual and non-individual users may only install the eWallet from the 5D Pay website www.5dpay.io, Google Play Store and/or Apple App Store. For any special setup by corporate or institutional clients, the users may contact us for assistance at Telephone +852 3188 7000, WhatsApp +852 9731 5987, WeChat (ID: YintranSupport) or email <Link color="#088AFF" href="mailto:support@yintran.com" >support@yintran.com</Link>.
                                </Text>
                            </Box>
                        </Box>

                        {/* 2.2     ELIGIBILITY  */}
                        <Box>
                            <Box paddingTop="8px" paddingBottom="16px">
                                <Text fontSize="16px">
                                    2.2 Eligibility
                                </Text>
                            </Box>
                            {/* paragraph */}
                            <Box color="gray" fontSize="16px">
                                <Text paddingBottom="24px" >
                                Subject to Clauses 2.3 and 3.1, the individual user is eligible to use the Platform and Services if the user registers with mobile phone number and name whereas the non-individual user is eligible to use the Platform and Services if it registers with mobile phone number or email address, and name. Please refer to Schedule 1 for age eligibility.
                                </Text>
                                <Text paddingBottom="24px" >
                                Subject to Clause 3.1, the eligible individual user, when his/her MSV is exceeding HKD3,000 or RMB3,000 or these two currencies combined MSV exceeding HKD3,000, is required to provide information such as HKID or PRC ID (or passport for non-HK/PRC residents), nationality, date of birth, and latest three months address proof; and the eligible non-individual user, when its MSV exceeding the said amount in this paragraph, is required to provide information such as the business registration, place of incorporation, and other information as required by the Company.
                                </Text>
                            </Box>
                        </Box>

                        {/* 2.3     INELIGIBILITY  */}
                        <Box>
                            <Box paddingTop="8px" paddingBottom="16px">
                                <Text fontSize="16px">
                                    2.3 Ineligibility
                                </Text>
                            </Box>
                            {/* paragraph */}
                            <Box color="gray" fontSize="16px">
                                <Text paddingBottom="24px" >
                                Any person or entity, not being invited by an existing Yintran 5D Pay user and not completing registration and meet eligible user criteria, is not eligible to be a 5D Pay user.
                                </Text>
                            </Box>
                        </Box>

                        {/* 2.4     AMENDMENT TO ELEGIBILITY  */}
                        <Box>
                            <Box paddingTop="8px" paddingBottom="16px">
                                <Text fontSize="16px">
                                    2.4 Amendment to Eligibility
                                </Text>
                            </Box>
                            {/* paragraph */}
                            <Box color="gray" fontSize="16px">
                                <Text paddingBottom="24px" >
                                Yintran reserves the sole right to amend the definition of the user eligibility. For the security and safety of the Platform, Yintran reserves the right to serve for the best interest of the users.
                                </Text>
                            </Box>
                        </Box>

                        {/* 3.     USER’S OBLIGATIONS & RESPONSIBILITIES*/}
                        <Box paddingTop="32px" paddingBottom="16px">
                            <Text fontWeight="medium" fontSize="16px">
                                3. User's Obligations and Responsibilities
                            </Text>
                        </Box>

                        {/* 3.1     ADDITIONAL INFORMATION REQUIRED */}
                        <Box>
                            <Box paddingTop="8px" paddingBottom="16px">
                                <Text fontSize="16px" paddingBottom="16px">
                                    3.1 Additional Information Required
                                </Text>
                                <Text fontSize="16px" fontStyle="italic" fontWeight="light" >
                                    Individual Users
                                </Text>
                            </Box>
                            {/* paragraph */}
                            <Box color="gray" fontSize="16px">
                                <Text paddingBottom="24px" >
                                Once the accumulated total inward transaction amount (transfer in and top up to the eWallet) exceeds HKD25,000 (or RMB25,000, or two currencies combined value exceeding the amount HKD25,000) for the first time in any twelve (12) rolling months, either remote ID verification and liveness verification, through 5D Pay Payment Platform or face-to-face verification, plus address proof is required before the user may further use the Platform and Services. The additional address document could be a utility bill or a bank statement that must be dated within 3 (three) months. It is the Company’s discretion to either use third party databases or other sources to verify the user’s information, and the user hereby consents to such enquiries conducted by the Company. Other information may also be required in order to comply with anti-money laundering and counter-terrorist financing laws, rules, regulations, codes, guidelines, circulars, and the Company policies.
                                    
                                </Text>
                            </Box>

                            <Box paddingTop="8px" paddingBottom="16px">
                                <Text fontSize="16px" fontStyle="italic" fontWeight="light" >
                                    Non-individual Users
                                </Text>
                            </Box>

                            {/* paragraph */}
                            <Box color="gray" fontSize="16px">
                                <Text paddingBottom="24px" >
                                Non-individual users may start to use the Platform and Services with basic information submitted provided that the transfer and MSV amounts are limited to the Primary level listed in Schedule 1 . Shall the transfer and MSV amounts exceed the Primary level or shall a non-individual user request to have higher amount respectively for transfer and MSV, additional agreement and documents are required to be entered into between the non-individual user in concern and the Company for using the Platform and Services to receive/transfer money with the higher limit specified in that agreement.
                                </Text>
                            </Box>
                        </Box>

                        {/* 3.2     DISCRETION FOR ACCEPTANCE*/}
                        <Box>
                            <Box paddingTop="8px" paddingBottom="16px">
                                <Text fontSize="16px">
                                    3.2 Discretion for Acceptance
                                </Text>
                            </Box>
                            {/* paragraph */}
                            <Box color="gray" fontSize="16px">
                                <Text paddingBottom="24px" >
                                Yintran reserves all rights to accept or reject the user’s account information with or without reasons.
                                </Text>
                            </Box>
                        </Box>

                        {/* 3.3     REPRESENTATION AND WARRANTY*/}
                        <Box>
                            <Box paddingTop="8px" paddingBottom="16px">
                                <Text fontSize="16px">
                                    3.3 Representation and Warranty
                                </Text>
                            </Box>
                            {/* paragraph */}
                            <Box color="gray" fontSize="16px">
                                <Text paddingBottom="24px" >
                                It is the user’s obligation and responsibility to update the said information to ensure the accuracy at all times. Any update for the user’s eWallet will only be effective upon the Company’s verification and approval. The Company has the sole discretion to accept or decline the update which may render the user’s eWallet to be deactivated, closed and terminated as a result of change and update made to the user’s eWallet.
                                 The user may contact us at Telephone +852 3188 7000, WhatsApp +852 9731 5987, WeChat (ID: YintranSupport) or email <Link color="#088AFF" href="mailto:support@yintran.com" >support@yintran.com</Link>.
                                
                                </Text>
                            </Box>
                        </Box>

                        {/* 3.4     USER’S INFORMATION UPDATE */}
                        <Box>
                            <Box paddingTop="8px" paddingBottom="16px">
                                <Text fontSize="16px">
                                    3.4 User's Information Update
                                </Text>
                            </Box>
                            {/* paragraph */}
                            <Box color="gray" fontSize="16px">
                                <Text paddingBottom="24px" >
                                    It is the user’s obligation and responsibility to update the said information to ensure the accuracy at all times. Any update for the user’s e-Wallet will only be effective the Company’s verification and approval. The Company has the sole discretion to accept or decline the update which may render the user’s e-Wallet to be deactivated, closed and terminated as a result of change and update made to the user’s eWallet. The user may contact us at +852 3188 7000, WhatsApp +852 9731 5987 or WeChat us or email us the enquiry at <Link color="#088AFF" href="mailto:support@yintran.com" >support@yintran.com</Link>.
                                    
                                </Text>
                            </Box>
                        </Box>

                        {/* 3.5     USER’S OBLIGATION TO SAFE KEEP CREDENTIALS*/}
                        <Box>
                            <Box paddingTop="8px" paddingBottom="16px">
                                <Text fontSize="16px">
                                    3.5 User's Obligation to Safe Keep Credentials
                                </Text>
                            </Box>
                            {/* paragraph */}
                            <Box color="gray" fontSize="16px">
                                <Text paddingBottom="24px" >
                                It is the user’s responsibility to safe keep his/her/its eWallet’s login information, password and User ID. The user is held entirely responsible and liable for the consequences of disclosing or lending such credentials to others, regardless of whether such disclosure is authorized or unauthorized, or voluntary or involuntary, and hence shall be entirely responsible and liable for the activities conducted by those third parties who obtained the user’s credentials.
                                </Text>
                            </Box>
                        </Box>

                        {/* 3.6     USER MUST BE THE BENEFICIAL OWNER */}
                        <Box>
                            <Box paddingTop="8px" paddingBottom="16px">
                                <Text fontSize="16px">
                                    3.6 User Must Be the Beneficial Owner
                                </Text>
                            </Box>
                            {/* paragraph */}
                            <Box color="gray" fontSize="16px">
                                <Text paddingBottom="24px" >
                                The individual user of eWallet must be the registered individual himself or herself, whereas the non-individual user of eWallet must be either the beneficial owner or the one having authority to use the eWallet on behalf of the beneficial owner.
                                </Text>
                            </Box>
                        </Box>

                        {/* 3.7     FALSE INFORMATION */}
                        <Box>
                            <Box paddingTop="8px" paddingBottom="16px">
                                <Text fontSize="16px">
                                    3.7 False Information
                                </Text>
                            </Box>
                            {/* paragraph */}
                            <Box color="gray" fontSize="16px">
                                <Text paddingBottom="24px" >
                                If false or inaccurate information is provided by the user or fraud has taken place when the user uses the Services, the detailed and related information will be provided to fraud prevention and credit reference agencies or law enforcement agencies. The information recorded and obtained by the Company will be accessed by the relevant law enforcement organizations in Hong Kong and/or other countries.
                                </Text>
                            </Box>
                        </Box>

                        {/* 3.8     CHANNELS OF COMMUNICATION TO USERS */}
                        <Box>
                            <Box paddingTop="8px" paddingBottom="16px">
                                <Text fontSize="16px">
                                    3.8 Channels of Communication to Users
                                </Text>
                            </Box>
                            {/* paragraph */}
                            <Box color="gray" fontSize="16px">
                                <Text paddingBottom="24px" >
                                By registering an account with the Platform to open and receive an eWallet to use the Services, the user consents to receive all communications from the Company electronically and the user confirms that he/she/it is able to access, receive and retain such communications. Communications may include the terms & conditions, the Company’s Privacy Policy, notices, announcements, and disclosures that the Company provides from time to time to the user in connection with the user’s eWallet at Platform and the user’s usage of the Services. The Company’s communication to the user by posting contents of the communications on the Company’s website or by emailing such to the user’s registered email address shall also be deemed as communications to the user.
                                </Text>
                            </Box>
                        </Box>

                        {/* 3.9        EFFECTIVENESS OF COMMUNICATION*/}
                        <Box>
                            <Box paddingTop="8px" paddingBottom="16px">
                                <Text fontSize="16px">
                                    3.9 Effectiveness of Communication
                                </Text>
                            </Box>
                            {/* paragraph */}
                            <Box color="gray" fontSize="16px">
                                <Text paddingBottom="24px" >
                                    Any amendments to the Terms and Conditions herein, all communications shall be
                                    deemed as received and accepted by the user seven (7) days after they have been
                                    posted on the Company’s website or sent to the user’s registered email address,
                                    as the case may be. It is the non-individual user’s obligation to keep the Company
                                    updated of the non-individual user’s latest email address in order to receive
                                    such email notifications from the Company electronically from time to time so
                                    long as the non-individual user maintains its eWallet at the Platform. The
                                    non-individual user may log into the non-individual user’s eWallet at the Platform
                                    to update its email address in the menu’s Settings page. Agreement of and consent
                                    to receiving communications from the Company cannot be withdrawn or cancelled.
                                    The Company reserves the right to close the non-individual user’s eWallet if the
                                    user chooses to withdraw or cancel its consent to receive communications from
                                    the Company. The user may contact us at Telephone +852 3188 7000, WhatsApp +852 9731 5987
                                    , WeChat (ID: YintranSupport) or email <Link color="#088AFF" href="mailto:support@yintran.com" >support@yintran.com</Link>
                                    if the user has any enquiry regarding the Company’s policy of communication with the user.
                                </Text>
                            </Box>
                        </Box>

                        {/* 4. e-WALLET ACCOUNT*/}
                        <Box paddingTop="32px" paddingBottom="16px">
                            <Text fontWeight="medium" fontSize="16px">
                                4. eWallet Account
                            </Text>
                        </Box>

                        {/* 4.1     SEGREGATION*/}
                        <Box>
                            <Box paddingTop="8px" paddingBottom="16px">
                                <Text fontSize="16px">
                                    4.1 Ready to Use
                                </Text>
                            </Box>
                            {/* paragraph */}
                            <Box color="gray" fontSize="16px">
                                <Text paddingBottom="24px" >
                                After the user registered and filled the user’s eWallet from his/her/its Funding Sources, the user may start withdrawing or receiving money from his/her/its eWallet to effect payment transfer to other individual and non-individual users who are either registered users at the Platform or non registered users at other banks and SVFs.
                                </Text>
                            </Box>
                        </Box>

                        {/* 4.2     NO INTEREST EARNED */}
                        <Box>
                            <Box paddingTop="8px" paddingBottom="16px">
                                <Text fontSize="16px">
                                    4.2 Segregation
                                </Text>
                            </Box>
                            {/* paragraph */}
                            <Box color="gray" fontSize="16px">
                                <Text paddingBottom="24px" >
                                The user’s stored value in the eWallet is segregated from the Company’s bank account that is solely for the Company’s operational purpose. The user's stored value is safe kept by a licensed bank in Hong Kong. In no time will the user’s stored value be used for the Company’s operational purpose or mingled with the Company’s bank account for operational purpose.
                                </Text>
                            </Box>
                        </Box>

                        {/* 4.3     NO INTEREST EARNED */}
                        <Box>
                            <Box paddingTop="8px" paddingBottom="16px">
                                <Text fontSize="16px">
                                    4.2 No Interest Earned
                                </Text>
                            </Box>
                            {/* paragraph */}
                            <Box color="gray" fontSize="16px">
                                <Text paddingBottom="24px" >
                                The user acknowledges that he/she/it will not be entitled to receive interest or other earning on the amounts in the balance of his/her/its eWallet.
                                </Text>
                            </Box>
                        </Box>

                        {/* 4.4     NEGATIVE BALANCE */}
                        <Box>
                            <Box paddingTop="8px" paddingBottom="16px">
                                <Text fontSize="16px">
                                    4.4 Negative Balance
                                </Text>
                            </Box>
                            {/* paragraph */}
                            <Box color="gray" fontSize="16px">
                                <Text paddingBottom="24px" >
                                Any negative balance in the user’s eWallet entitles the Company to immediately debit from user’s Funding Sources to replenish the eWallet from negative to the minimum required account balance as imposed by the Company with no less than 24 hours’ notice to the user.
                                </Text>
                            </Box>
                        </Box>



                        {/* 5.     e-WALLET GUIDELINES*/}
                        <Box paddingTop="32px" paddingBottom="16px">
                            <Text fontWeight="medium" fontSize="16px">
                                5. eWallet Guidelines
                            </Text>
                        </Box>

                        {/* 5.1     COMPLIANCE */}
                        <Box>
                            <Box paddingTop="8px" paddingBottom="16px">
                                <Text fontSize="16px">
                                    5.1 Compliance
                                </Text>
                            </Box>
                            {/* paragraph */}
                            <Box color="gray" fontSize="16px">
                                <Text paddingBottom="24px" >
                                The user must not use the eWallet in any way that is against any law, or cause the Company to breach or comply with any laws, regulations, codes, guidelines, and rules or act fraudulently or maliciously towards the Company.
                                </Text>
                            </Box>
                        </Box>

                        {/* 5.2     PURPOSE OF USAGE */}
                        <Box>
                            <Box paddingTop="8px" paddingBottom="16px">
                                <Text fontSize="16px">
                                    5.2 Purpose of Usage
                                </Text>
                            </Box>
                            {/* paragraph */}
                            <Box color="gray" fontSize="16px">
                                <Text paddingBottom="24px" >
                                    The user may either be an individual or non-individual user. The individual account is only for personal purpose and may not be used to receive payment for business transactions. The non-individual account may only be used for commercial purposes to collect and make payment.
                                </Text>

                            </Box>
                        </Box>

                        {/* 5.3  NO GUARANTEE AND NO ENDORSEMENT*/}
                        <Box>
                            <Box paddingTop="8px" paddingBottom="16px">
                                <Text fontSize="16px">
                                    5.3 No Guarantee and No Endorsement
                                </Text>
                            </Box>
                            {/* paragraph */}
                            <Box color="gray" fontSize="16px">
                                <Text paddingBottom="24px" >
                                The Services provided by the Company shall not be deemed as endorsement or guarantee from the Company for any individual or non-individual who is using the Company’s Platform or Services, nor shall any third party’s offers, goods or services featured or advertised or broadcasted on the Company’s website be deemed as endorsement or guarantee from the Company to users. Any information regarding third party’s offers, goods, and services provided on the Company’s website is for reference or informational purpose only, and the Company shall not be held liable for such contents’ truthfulness, accuracy and reliability.
                                </Text>

                            </Box>
                        </Box>


                        {/* 5.4 NON-IMPAIRMENT BY USERS*/}
                        <Box>
                            <Box paddingTop="8px" paddingBottom="16px">
                                <Text fontSize="16px">
                                    5.4 Non-impairment by Users
                                </Text>
                            </Box>
                            {/* paragraph */}
                            <Box color="gray" fontSize="16px">
                                <Text paddingBottom="24px" >
                                The user should not use the Platform or Services in a way that could damage, impair or compromise the Platform or Services, system, security, infrastructure, or interfere with other users.
                                </Text>
                            </Box>
                        </Box>

                        {/* 5.5     NON-AUTHORIZED USAGE BY USERS*/}
                        <Box>
                            <Box paddingTop="8px" paddingBottom="16px">
                                <Text fontSize="16px">
                                    5.5 Non-authorized Usage by Users
                                </Text>
                            </Box>
                            {/* paragraph */}
                            <Box color="gray" fontSize="16px">
                                <Text paddingBottom="24px" >
                                The user should not collaborate, collect, cooperate or harvest any information or data from the Services or utilize any system or decrypt or analyze any transmissions to or from the Platform and Services running between the users and the Company’s Platform; nor the user should attempt to use any such information or data to create product for any commercial use such as promotional or commercial activities.
                                </Text>
                            </Box>
                        </Box>


                        {/* 6.      LINKAGE OF USER’S OWN BANK ACCOUNT*/}
                        <Box paddingTop="32px" paddingBottom="16px">
                            <Text fontWeight="medium" fontSize="16px">
                                6. [LINKAGE OF USER’S OWN BANK ACCOUNT CURRENTLY INAPPLICABLE]
                            </Text>
                        </Box>

                        {/* 6.1  LINKAGE OF USER’S OWN BANK ACCOUNT WITH eWALLET */}
                        <Box>
                            <Box paddingTop="8px" paddingBottom="16px">
                                <Text fontSize="16px">
                                6.1 Linkage of User's Own Bank Account with eWallet
                                </Text>
                            </Box>
                            {/* paragraph */}
                            <Box color="gray" fontSize="16px">
                                <Text paddingBottom="24px" >
                                User may link his/her/its eWallet with accounts of the Hong Kong licensed banks (“Bank”) which are in the list of bank network coverage provided by the Platform and Services. When the user makes a payment transfer instruction that is funded by the user’s bank account, the user agrees to authorize the Company to initiate an electronic transfer from the user’s Linked Bank Account an amount the user instructs and specifies. The user is solely responsible for complying with any terms set by the user’s bank with respect to the user’s Linked Bank Account, including but not limited to any fees to be imposed by the bank or the Company in the cases of insufficient balance or overdraft. If there is an adjustment or fee imposed by the user’s bank or the Company that is associated with a payment that the user made when using the Services, the user authorizes the Company to credit or debit the adjustment or fee from the user’s Linked Bank Account to complete the user’s instructed transfer.
                                </Text>
                            </Box>
                        </Box>

                        {/* 6.2     USER’S AUTHORIZATION */}
                        <Box>
                            <Box paddingTop="8px" paddingBottom="16px">
                                <Text fontSize="16px">
                                    6.2 User's Authorization
                                </Text>
                            </Box>
                            {/* paragraph */}
                            <Box color="gray" fontSize="16px">
                                <Text paddingBottom="24px" >
                                    When the user’s Linked Bank Account is used as one of Funding Sources, the user has authorized the Company to initiate on the user’s behalf an electronic transfer from or to the user’s Linked Bank Account. The Company will make electronic transfers via a Hong Kong licensed bank, and the user’s Linked Bank Account will be debited or credited by a Hong Kong licensed bank in an amount the user instructs and specifies. The user agrees that the user’s authorization to the Company to make the electronic transfer is irreversible, and the user will not be able to cancel the electronic transfer. The Company is authorized to resubmit the user’s instruction to any licensed bank to debit the user’s authorized amount that is returned for a reason of insufficient or uncollected funds unless stated otherwise by the applicable Hong Kong law. The individual or non-individual user effectively guarantees the electronic transfer amount instructed by the user to the Company.
                                </Text>
                                <Text paddingBottom="24px" >
                                    The user hereby authorizes his/her/its Linked Bank which is registered by the user when setting up eWallet at the Platform, to effect transfers from the user’s Linked Bank Account to the Company’s bank account in accordance with such instructions as the user’s bank may receive from the Company’s bank account from time to time, provided always that the amount of any such transfer shall not exceed either (i) the amount of the transfer limit set by the user’s Linked Bank Account or (ii) the daily, monthly, and annual limits set by the Company, or (iii) regulatory required limits, whichever is the lowest.
                                </Text>
                            </Box>
                        </Box>

                        {/* 6.3 NO VERIFICATION REQUIRED BY BANK AND COMPANY WHEN EFFECTING USER’S INSTRUCTION*/}
                        <Box>
                            <Box paddingTop="8px" paddingBottom="16px">
                                <Text fontSize="16px">
                                    6.3 No Verification Required by Bank and Company When Effecting User's Instruction
                                </Text>
                            </Box>
                            {/* paragraph */}
                            <Box color="gray" fontSize="16px">
                                <Text paddingBottom="24px" >
                                The user agrees that the user’s bank and the Company shall not be obliged to ascertain whether or not the transfer notice is actually provided by the user. Furthermore, the user agrees to accept full responsibility for any overdraft (or increase in existing overdraft) on the user’s Linked Bank Account that may arise as a result of any such payment transfer instruction(s).
                                </Text>
                            </Box>
                        </Box>

                        {/* 6.4     USER’S LINKED BANK ACCOUNT STATUS*/}
                        <Box>
                            <Box paddingTop="8px" paddingBottom="16px">
                                <Text fontSize="16px">
                                    6.4 User's Linked Bank Account Status
                                </Text>
                            </Box>
                            {/* paragraph */}
                            <Box color="gray" fontSize="16px">
                                <Text paddingBottom="24px" >
                                    The user agrees to and MUST notify the Company of any change of the user’s registered Linked Bank Account and further agrees that should there be insufficient fund in the user’s Linked Bank Account to meet any payment transfer that the user hereby instructed and authorized the Company to effect the transfer, the user’s bank shall be entitled, at its discretion, not to effect such transfer in which event the user’s bank and the Company will collect the usual service charge from the user.
                                </Text>
                            </Box>
                        </Box>


                        {/* 6.5     WHEN STORED VALUE IN e-WALLET IS READY FOR USE*/}
                        <Box>
                            <Box paddingTop="8px" paddingBottom="16px">
                                <Text fontSize="16px">
                                    6.5 When Stored Value in eWallet is Ready for Use
                                </Text>
                            </Box>
                            {/* paragraph */}
                            <Box color="gray" fontSize="16px">
                                <Text paddingBottom="24px" >
                                    After the user registered and linked a bank account with the user’s eWallet in the Platform, and filled the user’s eWallet, the user may start withdrawing money from his/her/its eWallet to effect payment transfer to other individual and non-individual users. Transferring money from the user’s Linked Bank Account to his/her/its eWallet, the user acknowledges and understands that it usually takes effect in real-time for the fund transferred into the user’s eWallet, subject to the user’s bank operational time requirement.
                                </Text>
                            </Box>
                        </Box>


                        {/* 7. Transfer Limit*/}
                        <Box paddingTop="32px" paddingBottom="16px">
                            <Text fontWeight="medium" fontSize="16px">
                                7. Transfer Limit
                            </Text>
                        </Box>

                        <Box>
                            <Box paddingTop="8px" paddingBottom="16px">
                                <Text fontSize="16px">
                                7.1 Daily, monthly and annual transfer limit for individual and non-individual users are subject to the regulatory requirements. Please refer to Schedule 1.
                                </Text>
                            </Box>
                        </Box>

                        <Box>
                            <Box paddingTop="8px" paddingBottom="16px">
                                <Text fontSize="16px">
                                7.2 No limit is set on money transferred INTO user’s eWallet from others.
                                </Text>
                            </Box>
                            <Box paddingTop="8px" paddingBottom="16px">
                                <Text fontSize="16px" fontWeight="light">
                                7.3 A higher than daily transfer limit may be allowed and approved, subject to further documentation proof submitted by the user, the Company’s internal risk assessment and at the Company’s sole discretion and the approval by the relevant regulators.
                                </Text>
                            </Box>
                            {/* paragraph */}
                            <Box color="gray" fontSize="16px">
                                <Text paddingBottom="24px" >
                                    7.4 The Company will use the aggregate sum of the currency HKD and RMB balance to measure against the daily transfer limit.
                                </Text>
                            </Box>

                            <Box paddingTop="8px" paddingBottom="16px">
                                <Text fontSize="16px" fontWeight="light">
                                    7.5 The Company reserves the final decision to determine the daily, monthly and annual transfer limit for each user without reasons and without the user’s consent. Nevertheless, the limit may be adjusted according to the user’s request where the additional information will be required and subject to the Company’s approval. Once the user reaches the established daily, monthly or annual limits, the users will be notified.
                                </Text>
                            </Box>
                        </Box>

                        {/* 8.     MAXIMUM STORED VALUE*/}
                        <Box paddingTop="32px" paddingBottom="16px">
                            <Text fontWeight="medium" fontSize="16px">
                                8. Maximum Stored Value
                            </Text>
                            {/* paragraph */}
                            <Box color="gray" fontSize="16px">
                                <Text paddingBottom="24px" >
                                    A maximum stored value (MSV) is the maximum amount that the user may store in the eWallet. Any amount greater than the MSV will be automatically cashed out to the user’s Linked Bank Account subject to a transfer fee, and if the account is not linked to a bank account, the entire incoming fund will be rejected immediately to maintain the MSV. If the user wants to maintain a higher MSV, the user shall make a request to the Company subject to the documentation proof and justification submitted to the Company for approval where the Company reserves the entire discretion for the approval.
                                </Text>
                            </Box>

                            {/* paragraph */}
                            <Box color="gray" fontSize="16px">
                                <Text paddingBottom="24px" >
                                    MSV is measured at any time against the user’s balance. Unless the user applies to increase the MSV in advance which the application will be effective upon the Company’s approval based upon the documentation submitted to substantiate the need of the increment or the portion of the incoming fund exceeding the allowed MSV will be automatically cashed out to the user’s Linked Bank Account subject to a transfer fee, such as a fee imposed by user’s bank or FPS transfer out fee charged by the Company, or the entire incoming fund will be rejected if the user does not have a Linked Bank Account.

                                    The Company reserves the final decision to determine the MSV for each user without reasons and without the user’s consent.
                                </Text>
                            </Box>

                        </Box>


                        {/* 9. Fee*/}
                        <Box paddingTop="32px" paddingBottom="16px">
                            <Text fontWeight="medium" fontSize="16px">
                                9. Fee
                            </Text>
                        </Box>

                        {/* 10.1 Transfer Fee*/}
                        <Box>
                            <Box paddingTop="8px" paddingBottom="16px">
                                <Text fontSize="16px">
                                    9.1 Transfer Fee
                                </Text>
                            </Box>
                            {/* 10.1.1 Individual */}
                            <Box>
                                <Box paddingTop="8px" paddingBottom="16px">
                                    <Text fontSize="16px" fontWeight="light">
                                        9.1.1 Individual
                                    </Text>
                                </Box>
                                {/* paragraph */}
                                <Box color="gray" fontSize="16px">
                                    <Text paddingBottom="24px" >
                                        No fee is imposed for transfer between individual users (P2P) within the Platform. CASH-OUT between the individual’s eWallet and his/her Linked Bank Account, or transfer to other bank account or other SVF eWallet via FPS are waived for two times per calendar month, which are not accumulative and cannot carry forward to the next calendar month. Any such transfer.that exceeds twice per calendar month will be subject to a fee of HKD2 or RMB2, depending on the currency used for the transfer, per transfer.
                                    </Text>
                                    <Text paddingBottom="24px" >
                                        [EDDA fees CURRENTLY INAPPLICABLE]
                                        EDDA means a user authorize his/her/its banks in Hong Kong or other SVFs in Hong Kong to transfer funds into the user’s eWallet). There is an EDDA fee charged by those banks and SVFs and this fee will be charged back to you. A HKD10,000 or RMB10,000 maximum transfer limit for EDDA is currently allowed to reduce user’s risk from a high EDDA fees collected by his/her/its own Linked Bank. To prevent from being charged with the EDDA fees, the user can always log on his/her/its own internet banking to transfer money to user’s eWallet directly.
                                    </Text>
                                </Box>
                            </Box>

                            {/* 10.1.2 Non-individual */}
                            <Box>
                                <Box paddingTop="8px" paddingBottom="16px">
                                    <Text fontSize="16px" fontWeight="light">
                                        9.1.2 Non-individual
                                    </Text>
                                </Box>
                                {/* paragraph */}
                                <Box color="gray" fontSize="16px">
                                    <Text paddingBottom="24px" >
                                        Faster Payment Service (“FPS”) service fee of HKD2 or RMB2 , depending on the currency used for the transfer, will apply to each payment, transferring out, between a bank or other SVF and user’s eWallet during the promotional period until further notice by the Company. Such service fee is waived once a day for the first transfer in an amount between HKD1,000 to HKD100,000 (or RMB1,000 to RMB100,000). This waiver is not accumulative.
                                    </Text>
                                    <Text paddingBottom="24px" >
                                        FPS merchant fee

                                        FPS merchant fee refers to the prevailing fee charged and imposed by payor’s bank or SVF, which may change from time to time subject to such institution’s policy, on 5D Pay merchant payee receiving payment from payor via the Company’s FPS merchant payment collection service pursuant to the Hong Kong Interbank Clearing Limited’s protocol.
                                    </Text>
                                </Box>

                                {/* paragraph */}
                                <Box color="gray" fontSize="16px">
                                    <Text paddingBottom="24px" >
                                        [EDDA fees [CURRENTLY INAPPLICABLE]

                                        EDDA means a user authorize his/her/its banks in Hong Kong or other SVFs in Hong Kong to transfer funds into the user’s eWallet). There is an EDDA fee charged by those banks and SVFs and this fee will be charged back to you. A HKD10,000 or RMB10,000 maximum transfer limit for EDDA is currently allowed to reduce user’s risk from a high EDDA fee collected by his/her/its own Linked Bank. To prevent from being charged with the EDDA fees, the user can always log on his/her/its own internet banking to transfer money to user’s eWallet directly.
                                    </Text>
                                </Box>
                            </Box>
                        </Box>

                        {/* 10.2     REQUESTING FOR ACCOUNT HISTORY*/}
                        <Box>
                            <Box paddingTop="8px" paddingBottom="16px">
                                <Text fontSize="16px">
                                    9.2 Requesting for Account History
                                </Text>
                            </Box>
                            {/* paragraph */}
                            <Box color="gray" fontSize="16px">
                                <Text paddingBottom="24px" >
                                The 24-rolling month eWallet account activities record is kept for the user’s review. Upon request, any paper statement requirement for account record for the last 24 rolling months, HKD50 per page fee charge will be imposed. HKD80 per page will be imposed if the user requests for retrieving any record older than the active 24 rolling months record. The user may send request by email to <Link color="#088AFF" href="mailto:support@yintran.com">support@yintran.com</Link>. The oldest record that the user can retrieve is up to 60 months from the day of the request.
                                    
                                </Text>
                            </Box>
                        </Box>

                        {/* 10.3     FEE SCHEDULE AMENDMENT*/}
                        <Box>
                            <Box paddingTop="8px" paddingBottom="16px">
                                <Text fontSize="16px">
                                    9.3 Fee Schedule Amendment
                                </Text>
                            </Box>
                            {/* paragraph */}
                            <Box color="gray" fontSize="16px">
                                <Text paddingBottom="24px" >
                                All fee charges stated above and listed in Schedule 2 are subject to change from time to time with seven (7) days prior notification.
                                </Text>
                                <Text paddingBottom="24px" >
                                    The 24 rolling month e-Wallet account activities record is kept for the user’s review.
                                </Text>
                            </Box>
                        </Box>


                        {/* 10. LOYALTY PROGRAM*/}
                        <Box paddingTop="32px" paddingBottom="16px">
                            <Text fontWeight="medium" fontSize="16px">
                            10. Loyalty Program
                            </Text>
                        </Box>

                        {/* 10.1 JOIN THE LOYALTY PROGRAM*/}
                        <Box>
                            <Box paddingTop="8px" paddingBottom="16px">
                                <Text fontSize="16px">
                                10.1 Join the Loyalty Program
                                </Text>
                            </Box>
                            {/* paragraph */}
                            <Box color="gray" fontSize="16px">
                                <Text paddingBottom="24px" >
                                Loyalty Program is a free of charge reward program offered by the Company only to individual users who have successfully registered as 5D Pay eWallet users during the promotional period which is determined by the Company at the Company’s discretion.
                                </Text>
                            </Box>
                        </Box>


                        {/*  10.2 Earn 5D Pay Bonus Points*/}
                        <Box>
                            <Box paddingTop="8px" paddingBottom="16px">
                                <Text fontSize="16px">
                                10.2 Earn 5D Pay Bonus Points
                                </Text>
                            </Box>
                            {/* paragraph */}
                            <Box color="gray" fontSize="16px">
                                <Text paddingBottom="24px" >
                                Individual user will earn 5D Pay bonus points upon purchase of merchandize by 5D Pay eWallet or 5D Pay FPS. Please refer to Schedule 3 for details. 5D Pay bonus points will be converted into 5D Pay Dollar at the conversion rate as specified in Schedule 3.
                                </Text>
                            </Box>
                        </Box>

                        {/* 10.3 5D PAY DOLLAR REDEMPTION*/}
                        <Box>
                            <Box paddingTop="8px" paddingBottom="16px">
                                <Text fontSize="16px">
                                10.3 5D Pay Dollar Redemption
                                </Text>
                            </Box>
                            {/* paragraph */}
                            <Box color="gray" fontSize="16px">
                                <Text paddingBottom="24px" >
                                Individual user may redeem 5D Pay Dollar credited to user’s eWallet against next purchase at 5D Pay Acquired Merchant. The redemption of 5D Pay Dollar may be subject to additional terms and conditions posted on the Company website from time to time.
                                </Text>

                                <Text paddingBottom="24px" >
                                5D Pay bonus points and 5D Pay Dollar are non-transferable and cannot be exchanged in cash or for any other product or service. The reward cannot be used in conjunction with any other promotions and/or offers except stated otherwise.
                                </Text>

                                <Text pb="24px" >
                                All Loyalty Program benefits, amenities, offers, awards and services are subject to availability and may be changed by the Company at any time without notice.
                                </Text>
                            </Box>
                        </Box>


                        {/* 11.     DISPUTES*/}
                        <Box paddingTop="32px" paddingBottom="16px">
                            <Text fontWeight="medium" fontSize="16px">
                                11. Disputes
                            </Text>
                        </Box>

                        {/* 11.1 USER’S RESPONSIBILITY FOR GOODS AND SERVICES PURCHASED FROM ANOTHER USER OR THIRD PARTY VENDORS*/}
                        <Box>
                            <Box paddingTop="8px" paddingBottom="16px">
                                <Text fontSize="16px">
                                    11.1 User's Responsibility for Goods and Services Purchased from Another User or Third Party Vendors
                                </Text>
                            </Box>
                            {/* paragraph */}
                            <Box color="gray" fontSize="16px">
                                <Text paddingBottom="24px" >
                                The user acknowledges and agrees that the Company is not responsible for the goods or services the user received by using eWallet to make payment transfer for those goods and services, and only vendor(s) who user sought for goods and services against user’s payment is entirely responsible for warranty for those goods and services. It is user’s responsibility to (i) review vendor’s policies before making payment transfer to complete the purchase and transaction, and (ii) to resolve any disputes user may have with the vendor in issue. Shall user decide to take a legal action against his/her/its vendor and ask the Company for assistance, the Company may, but has no obligation, volunteer to provide assistance to the extent that the Company is able to facilitate provided that the user agrees to release and indemnify the Company, the Company’s officers, directors, agents, employees, suppliers and all affiliates from all claims and damages of any kind arising out of the user’s dispute with the vendor in issue. Furthermore, the Company does not guarantee its assistance to be satisfactory to the user, nor does the Company guarantee any particular outcome from its assistance to the user.
                                </Text>
                            </Box>
                        </Box>

                        {/* 11.2     DISPUTE BETWEEN USERS*/}
                        <Box>
                            <Box paddingTop="8px" paddingBottom="16px">
                                <Text fontSize="16px">
                                    11.2 Dispute between Users
                                </Text>
                            </Box>
                            {/* paragraph */}
                            <Box color="gray" fontSize="16px">
                                <Text paddingBottom="24px" >
                                    As regards any dispute between users without involving underlying
                                    commercial factors or transactions and solely related to the payment
                                    transfer technical issues, the user must file such dispute to the
                                    Company within 30 days from the dispute taking place by sending email
                                    to <Link color="#088AFF" href="mailto:support@yintran.com" >support@yintran.com</Link>.
                                    where the claim shall provide: the user’s name
                                    and the counterparty’s name, the disputed amount, time and date of
                                    dispute, details of the dispute and actions being taken so far, copies
                                    of the supporting documents, and any other vital information related
                                    to such dispute. The user shall provide full cooperation with the
                                    Company to provide the required and related documentation per the
                                    Company’s request, and shall not conceal or hide the related information.
                                    The Company will advise the user and the party involved on the outcome
                                    within three months from the date of receiving the dispute from the user.
                                    The user agrees that such advice shall be deemed final, conclusive and
                                    binding on the parties involved.
                                </Text>
                            </Box>
                        </Box>


                        {/* 13.     CLAIMS, FEES, FINES AND PENALTIES*/}
                        <Box paddingTop="32px" paddingBottom="16px">
                            <Text fontWeight="medium" fontSize="16px">
                                13. Claims, Fees, Fines and Penalties
                            </Text>
                        </Box>

                        {/* 13.1     LIABILITIES AND INDEMNIFICATION*/}
                        <Box>
                            <Box paddingTop="8px" paddingBottom="16px">
                                <Text fontSize="16px">
                                    13.1 Liabilities and Indemnification
                                </Text>
                            </Box>
                            {/* paragraph */}
                            <Box color="gray" fontSize="16px">
                                <Text paddingBottom="24px" >
                                The user is solely liable for all bank charges, charges arising from reversals 
                                and claims, fees, fines, penalties, the Company’s administrative cost incurred, and other liabilities caused to the Company, other users, or third parties in relation to the user’s breach of this Agreement when using Platform and/or the Services. The user agrees to indemnify the Company, the Company’s affiliates, other relevant users, and any relevant third party against the liabilities resulting from his/her/its breach of this Agreement.
                                    
                                </Text>
                            </Box>
                        </Box>

                        {/* 13.2     CLOSURE OF e-WALLET AND RECOVERY OF DAMAGE*/}
                        <Box>
                            <Box paddingTop="8px" paddingBottom="16px">
                                <Text fontSize="16px">
                                    13.2 Closure of eWallet and Recovery of Damage
                                </Text>
                            </Box>
                            {/* paragraph */}
                            <Box color="gray" fontSize="16px">
                                <Text paddingBottom="24px" >
                                If the Company determines, at the Company’s sole judgment, with or without reasons, that the user may have breached this Agreement and the user and the user’s eWallet present risk(s) or security concerns, or if the Company is unable to verify the user’s identity, the Company may immediately take actions to protect the Company and other users or other third parties. These actions may include, but are not limited to, suspension of the user’s access to his/her/its eWallet and/or the Company’s Platform and Services, at the Company’s discretion, for 30 days or longer until the user rectifies the breach. Furthermore, the Company may take action to claim and recover the amount incurred as a result of the user’s breach, close the user’s eWallet, contact the user’s Linked Bank, contact third parties involved in the user’s breach, and resort to legal action and enforcement against the user, the user’s affiliates and the user’s related third parties. The Company may decide, in future, not to provide the Platform and/or Services, with or without reasons, to such user, his/her/its related parties, associates and affiliates.
                                    
                                </Text>
                            </Box>
                        </Box>

                        {/* 14.     LIMITED LIABILITIES*/}
                        <Box paddingTop="32px" paddingBottom="16px">
                            <Text fontWeight="medium" fontSize="16px">
                                14. Limited Liabilities
                            </Text>
                            {/* paragraph */}
                            <Box color="gray" fontSize="16px">
                                <Text paddingBottom="24px" >
                                Unless the Company has been grossly negligent, committed fraud or willful misconduct or default, the Company will NOT be liable to the user for any losses that the user may have suffered or costs that the user has incurred because (i) the user does not receive any notifications in a timely manner; (ii) the Services does not work; (iii) any third party service providers, such as vendors or software service providers, have compromised service level or failed to achieve adequate service level or been unable to provide services; (iv) the software the user uses in connection with the Services is damaged or corrupted; (v) the user is unable to access or use the Services for any reason or there is a delay in the usage. Under no circumstances is the Company liable for any presumable, indirect or consequential losses or damage.
                                </Text>
                            </Box>
                        </Box>

                        {/* 15.     UNAUTHORIZED ACCESS TO USER’S e-WALLET*/}
                        <Box paddingTop="32px" paddingBottom="16px">
                            <Text fontWeight="medium" fontSize="16px">
                                15. Unauthorized Access to User's eWallet
                            </Text>
                            {/* paragraph */}
                            <Box color="gray" fontSize="16px">
                                <Text paddingBottom="24px" >
                                    The user shall report to the Company immediately, within seven (7) days,
                                    by contacting us at Telephone +852 3188 7000, WhatsApp +852 9731 5987,
                                    WeChat (ID: YintranSupport) or email
                                    <Link color="#088AFF" href="mailto:support@yintran.com" >support@yintran.com</Link> if
                                    the user’s eWallet has the following activities: (i) a payment transfer has been
                                    administered inaccurately; (ii) eWallet has been log on without the user’s authorization
                                    or agreement; (iii) a payment transfer has transpired without the user’s
                                    authorization or agreement; or (iv) account activities summary contains a miscalculation.
                                    If any unauthorized payment transfer is caused by an access authorized by the user or
                                    the user’s disclosure of his/her/its account information, such payment made shall be
                                    deemed as if it were made by the user and the user shall therefore be bound by the validity
                                    of such payment transfer. If the Company’s Platform or Services caused an error in account
                                    activities summary or incorrect payment transferred without involving the user’s authorization,
                                    the Company will reply to the user within seven (7) Business Days after receiving email report
                                    from the user if the error is caused by the Company’s Platform or Services (phone call report
                                    shall be followed by email report). The Company will correct the error promptly at the sole
                                    discretion of the Company, if the error is solely caused by the Company as determined at the
                                    sole discretion of the Company. If not caused by the Company, the Company is allowed to have
                                    a longer time to investigate the cause, and determine if it shall credit or debit the user’s 
                                    eWallet the disputed amount, if any, caused by the error.
                                </Text>
                            </Box>
                        </Box>


                        {/* 15.     TERMINATION OF SERVICES AND ACCOUNT CLOSURE*/}
                        <Box paddingTop="32px" paddingBottom="16px">
                            <Text fontWeight="medium" fontSize="16px">
                                15. Termination of Services and Account Closure
                            </Text>
                        </Box>

                        {/* 16.1     CLEARANCE OF BALANCE BEFORE TERMINATION */}
                        <Box>
                            <Box paddingTop="8px" paddingBottom="16px">
                                <Text fontSize="16px">
                                    15.1 Clearance of Balance before Termination
                                </Text>
                            </Box>
                            {/* paragraph */}
                            <Box color="gray" fontSize="16px">
                                <Text paddingBottom="24px" >
                                When the user decides to cease using the Platform and Services and terminate his/her/its eWallet account, the user, first, is required to withdraw the balance in his/her/its eWallet by cashing out the balance to his/her/its Linked Bank Account or by transferring the balance to other users within the Platform or other bank account or SVF account, then email or whatsapp the account closure request to the Company from the user’s registered email or mobile number, as the case may be. In case the user cannot clear the balance by the above methods, the user may email or whatsapp to the Company from the user’s registered email or mobile number or request for setting up an appointment to collect the balance, in a form of a check issued by the Company, at the Company office, within one (1) month after receipt of the notice from the Company to collect the balance. The clearing balance for the purpose of the eWallet account closure is free of charge.
                                </Text>
                            </Box>
                        </Box>

                        {/* 16.2     CLOSURE OF e-WALLET */}
                        <Box>
                            <Box paddingTop="8px" paddingBottom="16px">
                                <Text fontSize="16px">
                                    15.2 Closure of eWallet
                                </Text>
                            </Box>
                            {/* paragraph */}
                            <Box color="gray" fontSize="16px">
                                <Text paddingBottom="24px" >
                                Subject to Clause 15.3, if the account is idle for 12 months, and if there are no pending or unsettled transactions or outstanding disputes, the Company will, once the account balance is cleared, proceed with the termination of such user’s eWallet account whereas subject to Clause 15.1, after user requests the Company to close the account, and if there are no pending or unsettled transactions or outstanding disputes and the account balance is cleared, the Company shall forthwith close such user’s eWallet account.
                                </Text>
                            </Box>
                        </Box>

                        {/* 16.3     IDLE e-WALLET*/}
                        <Box>
                            <Box paddingTop="8px" paddingBottom="16px">
                                <Text fontSize="16px">
                                    15.3 Idle eWallet
                                </Text>
                            </Box>
                            {/* paragraph */}
                            <Box color="gray" fontSize="16px">
                                <Text paddingBottom="24px" >
                                In case an eWallet is idle without activities for 12 months, the Company will start imposing HKD25 monthly as an account maintenance fee on the user for six (6) months and after six (6) months, the maintenance fee will be increased to HKD60 monthly plus any other charges imposed by the bank holding this segregated account plus the Company’s additional administrative cost until the user’s stored value account balance becomes zero, then the eWallet account will be ceased and entirely terminated. Nevertheless, the monthly account maintenance fee will be ceased to impose if the user’s eWallet during the idle period is reactivated after his/her/its request for reactivation is approved by the Company.
                                </Text>
                            </Box>
                        </Box>

                        {/* 16.4     DISCRETIONAL SUSPENSION BY THE COMPANY*/}
                        <Box>
                            <Box paddingTop="8px" paddingBottom="16px">
                                <Text fontSize="16px">
                                    15.4 Discretional Suspension by the Company
                                </Text>
                            </Box>
                            {/* paragraph */}
                            <Box color="gray" fontSize="16px">
                                <Text paddingBottom="24px" >
                                Separately, the Company may at its sole discretion suspend, then close the user’s eWallet account or cease the user’s access to the Services without giving prior notification to the user and without being liable to the user for any loss or damage incurred thereby shall the user breach the Agreement or if the Company suspect any illegal activities engaged or conducted by the user when using the Services, such as (i) violation of laws, regulations, codes, ordinances, or guidelines; (ii) committing fraud; (iii) using the Platform and/or Service to engage illegal or criminal activities including but not limited to receiving proceeds generated from crimes or inappropriate activities; (iv) providing false information to the Company; (v) behaving inappropriately towards the Company’s staff or other users; (vi) becoming ineligible to use the Platform and/or Service; or (vii) allowing other entities to use his/her/its eWallet account.
                                </Text>
                            </Box>
                        </Box>

                        {/* 16.5     CEASE OF SERVICES AND LINKAGE WITH USER’S BANK OR SVF ACCOUNT*/}
                        <Box>
                            <Box paddingTop="8px" paddingBottom="16px">
                                <Text fontSize="16px">
                                
                                    15.5 Cease of Services and Linkage with User's Bank or SVF Account
                                </Text>
                            </Box>
                            {/* paragraph */}
                            <Box color="gray" fontSize="16px">
                                <Text paddingBottom="24px" >
                                The Services will immediately cease upon the account’s closure. It is the user’s obligation to notify his/her/its own Linked Bank or other SVF account that his/her/its eWallet account is being terminated and he/she/it has no longer has access to the Platform and Services.
                                </Text>
                            </Box>
                        </Box>


                        {/* 16. INDEMNIFICATION*/}
                        <Box paddingTop="32px" paddingBottom="16px">
                            <Text fontWeight="medium" fontSize="16px">
                                16. Indemnification
                            </Text>
                        </Box>

                        {/*17.1 Indemnification */}
                        <Box>
                            <Box paddingTop="8px" paddingBottom="16px">
                                <Text fontSize="16px">
                                    16.1 Indemnification
                                </Text>
                            </Box>
                            {/* paragraph */}
                            <Box color="gray" fontSize="16px">
                                <Text paddingBottom="24px" >
                                The user agrees to indemnify and hold harmless the Company, its officers, directors, agents, employees, suppliers, related parties, and affiliates from any dispute as a result of his/her/its using the Platform and/or Services and eWallet provided by the Company. Furthermore, the user agrees to indemnify and hold harmless the Company, its officers, directors, agents, employees, suppliers, related parties, and affiliates from any dispute related to any transaction between the user and any other third parties.
                                </Text>
                            </Box>
                        </Box>

                        {/* 17.2     INDEMINIFICATION AGAINST LEGAL PROCEEDINGS*/}
                        <Box>
                            <Box paddingTop="8px" paddingBottom="16px">
                                <Text fontSize="16px">
                                    16.2 Indemnification Against Legal Proceedings
                                </Text>
                            </Box>
                            {/* paragraph */}
                            <Box color="gray" fontSize="16px">
                                <Text paddingBottom="24px" >
                                The user further agrees to indemnify and hold harmless the Company, its officers, directors, agents, employees, suppliers, related parties, and affiliates from any claims, actions, proceedings, and suits and related liabilities, damages, settlements, penalties, fines, costs or expenses (including legal fees and litigation expenses) arising from the user’s actual or alleged violation of this Agreement or any other applicable Company’s policies or any third party’s rights or any laws regulations and rules governing the Company as a result of the user’s use of the Platform and/or Services.
                                </Text>
                            </Box>
                        </Box>

                        {/* 17.3     DISPUTE RESOLUTION*/}
                        <Box>
                            <Box paddingTop="8px" paddingBottom="16px">
                                <Text fontSize="16px">
                                    16.3 Dispute Resolution
                                </Text>
                            </Box>
                            {/* paragraph */}
                            <Box color="gray" fontSize="16px">
                                <Text paddingBottom="24px" >
                                    All disputes arising from this Agreement shall first resort to an amicable solution, failure of which the user and the Company irrevocably agree that the courts of the Hong Kong Special Administrative Region (“HKSAR”) shall have an exclusive jurisdiction in relation to any claim or dispute concerning this Agreement.
                                </Text>
                            </Box>
                        </Box>

                        {/* 17.4     MAXIMUM LIABILITY*/}
                        <Box>
                            <Box paddingTop="8px" paddingBottom="16px">
                                <Text fontSize="16px">
                                    16.4 Maximum Liability
                                </Text>
                            </Box>
                            {/* paragraph */}
                            <Box color="gray" fontSize="16px">
                                <Text paddingBottom="24px" >
                                The user agrees that the maximum liability and claim against the Company and its officers, directors, employees, affiliates and agents, if any, in relation to the user’s use of the Platform and Services shall in no time exceed the fee charge collected by the Company from the user in relating to the transaction being claimed.
                                </Text>
                            </Box>
                        </Box>

                        {/* 17.5     SERVICES FREE FROM WARRANTY*/}
                        <Box>
                            <Box paddingTop="8px" paddingBottom="16px">
                                <Text fontSize="16px">
                                    16.5 Services Free from Warranty
                                </Text>
                            </Box>
                            {/* paragraph */}
                            <Box color="gray" fontSize="16px">
                                <Text paddingBottom="24px" >
                                The Platform and Services are provided "as is" and “as available”, without any representation or warranty of usage or availability, whether express, implied or statutory. The user’s use of the Services is at his/her/its own risk. The Company, its officers, directors, agents, employees, suppliers and affiliates, specifically disclaim any explicit or implied warranties for any usefulness, fitness or a particular purpose of the Platform and Services and non-infringement when using the Platform and Services. The Company has neither control of nor liability for any product or services that are paid by using the Platform and Services and cannot ensure that any third party the user transacts with will complete the transaction.
                                </Text>
                            </Box>
                        </Box>

                        {/*17.6     FREE FROM REPRESENTATION AND WARRANTY */}
                        <Box>
                            <Box paddingTop="8px" paddingBottom="16px">
                                <Text fontSize="16px">
                                    16.6 Free from Representation and Warranty
                                </Text>
                            </Box>
                            {/* paragraph */}
                            <Box color="gray" fontSize="16px">
                                <Text paddingBottom="24px" >
                                The Company does not represent or warrant that the Platform and/or Services will meet the user’s requirements and expectation, or the Platform and/or Services will be continuous, uninterrupted, secured, timely, or error-free, or defects of the Services will be corrected. No advice or information, whether oral or written, obtained by the user from the Company or through the Platform and/or Services will create any warranty which is not expressly stated herein or which is indirectly inferred herein.
                                </Text>
                            </Box>
                        </Box>

                        {/* 17.7     DISCLAIMER*/}
                        <Box>
                            <Box paddingTop="8px" paddingBottom="16px">
                                <Text fontSize="16px">
                                    16.7 Disclaimer
                                </Text>
                            </Box>
                            {/* paragraph */}
                            <Box color="gray" fontSize="16px">
                                <Text paddingBottom="24px" >
                                The Company shall not be held responsible for any interruptions to or system failures in the Platform or Services that may affect the processing and completion of the user’s payment transfer or settlement of the transaction with the payment made via using the Services. The disclaimer shall apply to all circumstances permitted by the laws of the HKSAR and all the applicable jurisdictions.
                                </Text>
                            </Box>
                        </Box>

                        {/* 17.8     SUSPENSION OF USAGE OF SERVICES*/}
                        <Box>
                            <Box paddingTop="8px" paddingBottom="16px">
                                <Text fontSize="16px">
                                    16.8 Suspension of Usage of Services
                                </Text>
                            </Box>
                            {/* paragraph */}
                            <Box color="gray" fontSize="16px">
                                <Text paddingBottom="24px" >
                                The user further understands and agrees that the Company shall not be held liable for any losses or damages resulting from suspension of the Services due to extraordinary events or circumstances beyond the Company’s control. Under such circumstances, when the Company suspends the user’s access to the Platform and Services and eWallet, the linkage between the user’s eWallet and user’s Linked Bank, if any, will also be simultaneously suspended.
                                </Text>
                            </Box>
                        </Box>

                        {/* 17.9     ASSIGNMENT AND GOVERNING LAW*/}
                        <Box>
                            <Box paddingTop="8px" paddingBottom="16px">
                                <Text fontSize="16px">
                                    16.9 Assignment and Governing Law
                                </Text>
                            </Box>
                            {/* paragraph */}
                            <Box color="gray" fontSize="16px">
                                <Text paddingBottom="24px" >
                                The user is not allowed to transfer or assign any rights or obligations he/she/it has under this Agreement. The Company reserves the right to transfer or assign any rights or obligations under this Agreement at any time without the user’s consent. The user hereby agrees that the laws of the HKSAR govern this Agreement and any claim or dispute that has arisen or may arise between the user and the Company.
                                </Text>
                            </Box>
                        </Box>

                        {/* 17.10     ENTIRTY AND NON-WAIVER*/}
                        <Box>
                            <Box paddingTop="8px" paddingBottom="16px">
                                <Text fontSize="16px">
                                    16.10 Entirty and Non-waiver
                                </Text>
                            </Box>
                            {/* paragraph */}
                            <Box color="gray" fontSize="16px">
                                <Text paddingBottom="24px" >
                                    This Agreement, along with any applicable and related Company’s policies shall form the entire understanding between the user and the Company with respect to Platform and the Services. Any failure of the Company to enforce any right or provision of this Agreement shall not constitute a waiver of such right or provision.
                                </Text>
                            </Box>
                        </Box>

                        {/* 17.11     SURVIVAL*/}
                        <Box>
                            <Box paddingTop="8px" paddingBottom="16px">
                                <Text fontSize="16px">
                                    16.11 Survival
                                </Text>
                            </Box>
                            {/* paragraph */}
                            <Box color="gray" fontSize="16px">
                                <Text paddingBottom="24px" >
                                    If any provision or term of this Agreement is invalid or unenforceable under law, the remaining provisions shall remain valid, in full force and effect. Consent to receive communication from the Company, all and any matters related to bank charges disputes with other users, the user’s liability, actions that the Company may take against the user, disputes with the Company, as well as any other terms which in their nature should survive the termination of this Agreement.
                                </Text>
                            </Box>
                        </Box>


                        {/* 18.     USER’S ENQUIRIES*/}
                        <Box paddingTop="32px" paddingBottom="16px">
                            <Text fontWeight="medium" fontSize="16px" paddingBottom="16px">
                                17. User's Enquiries
                            </Text>
                            {/* paragraph */}
                            <Box color="gray" fontSize="16px">
                                <Text paddingBottom="24px" >
                                The user may contact the Company direct at Telephone +852 3188 7000, 
                                WhatsApp +852 9731 5987, WeChat us (ID: YintranSupport) or 
                                email <Link color="#088AFF" href="mailto:support@yintran.com" >support@yintran.com</Link> for questions. The Company will endeavor 
                                to respond to the user’s enquiries as soon as appropriate.
                                </Text>
                            </Box>
                        </Box>


                        {/* 19.     USER’S DATA PRIVACY*/}
                        <Box paddingTop="32px" paddingBottom="16px">
                            <Text fontWeight="medium" fontSize="16px" paddingBottom="16px">
                                18. User's Data Privacy
                            </Text>
                            {/* paragraph */}
                            <Box color="gray" fontSize="16px">
                                <Text paddingBottom="24px" >
                                The user’s data with the Company will be protected pursuant to the Hong Kong Personal Data (Privacy) Ordinance (Chapter 486).
                                </Text>
                            </Box>
                        </Box>

                        {/* 20. Definition*/}
                        <Box paddingTop="32px" paddingBottom="16px">
                            <Text fontWeight="medium" fontSize="16px" paddingBottom="16px">
                                19. Definitions
                            </Text>
                            {/* paragraph */}
                            <Box fontSize="16px">
                                <Text paddingBottom="24px" color="gray">
                                    "Bank" means a licensed bank in Hong Kong.
                                </Text>
                                <Text paddingBottom="24px" color="gray">
                                    "Company" or "the Company" means Yintran Group Holdings Limited and its successors, affiliates, or assigns which operates a payment transfer platform facilitating payment transfer among the users via a wide banking network connected by the Company.
                                </Text>
                                <Text paddingBottom="24px" color="gray">
                                    Daily Received Amount Limit: None
                                </Text>
                                <Text paddingBottom="24px" color="gray">
                                    Daily Transfer-IN Limit: NO limit on money transferred-INTO user’s account
                                </Text>
                                <Text paddingBottom="24px" color="gray">
                                    Daily Transfer-OUT Limit:
                                </Text>

                                <Box>
                                    <Text paddingBottom="12px" color="gray" fontWeight="light" fontStyle="italic">
                                        INDIVIDUAL: Top-up or payment or cash-out: HKD 30,000.00
                                    </Text>
                                    <Text paddingBottom="24px" color="gray" fontWeight="light" fontStyle="italic">
                                        NON-INDIVIDUAL: A higher than daily transfer limit may be allowed and approved subject to further documentation proof submitted by user, and the approval by the Company at the Company’s sole discretion.
                                    </Text>
                                </Box>

                                <Text paddingBottom="24px" color="gray">
                                    Once MSV is reached and if the user has a linked bank account, there is a mechanism to “auto cash out” the excess amount to the user’s linked bank account.  Whereas the user does not have a linked bank account, if an aggregate of an incoming fund with the balance in the Yintran account exceeds the MSV, the incoming fund will be returned.
                                </Text>
                                <Text paddingBottom="24px" color="gray">
                                    The Company reserves the right to use the sum of the currency HKD and CNY MSV amount to limit the MSV with respect to the individual and non-individual account type.
                                </Text>
                                <Text paddingBottom="24px" color="gray">
                                    The Company reserves the final decision to determine the MSV for each user without reasons and without the user’s consent.
                                </Text>
                                <Text paddingBottom="24px" color="gray">
                                    "Minimum Account Balance" means minimum balance required to be maintained in the Yintran e-Wallet.  The Minimum Account Balance may be increased depending on user’s usage. The Company reserves the right to change the Minimum Account Balance from time to time at its sole discretion with or without reasons.
                                </Text>
                                <Text paddingBottom="24px" color="gray">
                                    "Non-individual Account" means an account or e-Wallet established for business and not for personal purposes.  The Company has the right, not the obligation, to determine the account type (i.e. individual or non-individual) at its sole discretion with or without reason.
                                </Text>
                                <Text paddingBottom="24px" color="gray">
                                    "Recipient (Payee)" means the user receiving payment from another user.
                                </Text>
                                <Text paddingBottom="24px" color="gray">
                                    "Registered Funding Sources"     means the funding sources for the user’s e-Wallet, such as the user’s Linked Bank Account.
                                </Text>


                                <Text paddingBottom="24px" color="gray">
                                    "Sender (Payer)"     means the user making payment transfer to another user.
                                </Text>
                                <Text paddingBottom="24px" color="gray">
                                    "Services"     means all payment transfer related services offered and provided by the Company through the Company’s website, iOS/ Android applications, or any other related services provided by the Company.
                                </Text>
                                <Text paddingBottom="24px" color="gray">
                                    "Settings" or "Settings page"     means the Settings page in e-Wallet allowing the user to update his/her/its  profile and registered information. Confirmation and authentication are required prior to the user being allowed to affect the update.  Nevertheless, the final approval of the update is entirely at the Company’s sole discretion.
                                </Text>
                                <Text paddingBottom="24px" color="gray">
                                    "Users"     means any individual or non-individual that has registered with the Platform to use the Services and has an e-Wallet at the Platform.
                                </Text>

                            </Box>
                        </Box>

                    </Box>


                </Box>
            </Box>
        </Center>


    )
}
