import {
    Box,
    Flex,
    Text,
    IconButton,
    Button,
    Stack,
    Collapse,
    Icon,
    Popover,
    PopoverTrigger,
    Spacer,
    Center,
    PopoverContent,
    useColorModeValue,
    useBreakpointValue,
    Container,
    useDisclosure,
    HStack,
    Image,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    chakra,
    Link,
    FormControl,
    FormLabel,
    Input,
    Checkbox,
    Heading,
} from '@chakra-ui/react';
import {
    HamburgerIcon,
    CloseIcon,
    ChevronDownIcon,
    ChevronRightIcon,
} from '@chakra-ui/icons';
import { ColorModeSwitcher } from './ColorModeSwitcher';
import logo from './assets/logoR.png';


export default function Navbar() {
    const { isOpen, onToggle } = useDisclosure();
    return (
        <Box >
            <Flex
                // position={'fixed'} 
                // (wrecks the navbar)
                justify={{ base: 'center' }}
                w={'full'}
                // bg={useColorModeValue('white', 'gray.800')}
                color={useColorModeValue('gray.600', 'white')}
                minH={'100px'}
                py={{ base: 2 }}
                px={{ base: 4 }}
                borderStyle={'solid'}
                // borderColor={useColorModeValue('gray.200', 'gray.900')}
                align={'center'}
                zIndex={'9999'}>
                <Flex
                    flex={{ base: 1, md: 'auto' }}
                    // ml={{ base: -2 }}
                    display={{ base: 'flex', md: 'none' }}>
                    <Popover>
                        <PopoverTrigger>
                        <IconButton
                        onClick={onToggle}
                        icon={
                            isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
                        }
                        variant={'ghost'}
                        aria-label={'Toggle Navigation'}
                    />
                    </PopoverTrigger>
                    </Popover>
                </Flex>

                <Flex
                    // flex={{ base: 1 }} justify={{ base: 'center', md: 'start' }}
                    justify={{ base: 'center' }}
                >
                    <HStack
                        mt={{ base: 'none', md: 1 }}
                        minWidth={{ base: 'none', md: '186px' }}
                    >
                        <Center>
                        <Link href="/" >
                            <Image src={logo}
                                textAlign={useBreakpointValue({ base: 'center', md: 'left' })}
                                h={8}
                                justify={'center'}
                            />
                            </Link>
                        </Center>
                    </HStack>
                </Flex>

                <Spacer display={{ base: 'none', md: 'flex' }} />

                <Flex>
                    <Flex display={{ base: 'none', md: 'flex' }}>
                        <DesktopNav />
                    </Flex>
                </Flex>


                <Spacer display={{ base: 'none', md: 'flex' }} />

                <HStack
                    flex={{ base: 1, md: 0 }}
                    justify={'flex-end'}
                    direction={'row'}
                    spacing={6}>
                    <ColorModeSwitcher
                        rounded='full'
                        // color='gray.200'
                        justifySelf="flex-end" />
                    {/* <Signinmodal /> */}
                    <Signupmodal />
                </HStack>

            </Flex>

            <Collapse in={isOpen} animateOpacity>
                {/* <Box h={'100px'}> */}
                <MobileNav />
                {/* </Box> */}
            </Collapse>
        </Box>
    );
}

const DesktopNav = () => {
    const linkColor = useColorModeValue('gray.600', 'gray.200');
    const linkHoverColor = useColorModeValue('gray.800', 'white');
    const popoverContentBgColor = useColorModeValue('white', 'gray.800');

    return (
        <Stack direction={'row'} spacing={4} align='center'>
            {NAV_ITEMS.map((navItem) => (
                <Box key={navItem.label}>
                    <Popover trigger={'hover'} placement={'bottom-start'}>
                        <PopoverTrigger>
                            <Link
                                p={2}
                                href={navItem.href ?? '#'}
                                fontSize={'md'}
                                fontWeight={500}
                                color={linkColor}
                                _hover={{
                                    textDecoration: 'none',
                                    color: linkHoverColor,
                                }}>
                                {navItem.label}
                            </Link>
                        </PopoverTrigger>

                        {navItem.children && (
                            <PopoverContent
                                border={0}
                                boxShadow={'xl'}
                                bg={popoverContentBgColor}
                                p={4}
                                rounded={'xl'}
                                minW={'sm'}>
                                <Stack>
                                    {navItem.children.map((child) => (
                                        <DesktopSubNav key={child.label} {...child} />
                                    ))}
                                </Stack>
                            </PopoverContent>
                        )}
                    </Popover>
                </Box>
            ))}
        </Stack>
    );
};

const DesktopSubNav = ({ label, href, subLabel }: NavItem) => {
    return (
        <Link
            href={href}
            role={'group'}
            display={'block'}
            p={2}
            rounded={'md'}
            _hover={{ bg: useColorModeValue('pink.50', 'gray.900') }}>
            <Stack direction={'row'} align={'center'}>
                <Box>
                    <Text
                        transition={'all .3s ease'}
                        _groupHover={{ color: 'pink.400' }}
                        fontWeight={500}>
                        {label}
                    </Text>
                    <Text fontSize={'sm'}>{subLabel}</Text>
                </Box>
                <Flex
                    transition={'all .3s ease'}
                    transform={'translateX(-10px)'}
                    opacity={0}
                    _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
                    justify={'flex-end'}
                    align={'center'}
                    flex={1}>
                    <Icon color={'pink.400'} w={5} h={5} as={ChevronRightIcon} />
                </Flex>
            </Stack>
        </Link>
    );
};

const MobileNav = () => {
    const linkColor = useColorModeValue('gray.600', 'gray.200');
    const linkHoverColor = useColorModeValue('gray.800', 'white');
    const popoverContentBgColor = useColorModeValue('white', 'gray.800');

    return (
        <Stack direction={'column'} spacing={4} align='left'>
            {NAV_ITEMS.map((navItem) => (
                <Box key={navItem.label}>
                    <Popover
                    //   trigger={'hover'} 
                    //   placement={'top'}
                    >
                        <PopoverTrigger>
                            {/* <Link
                                p={2}
                                href={navItem.href ?? '#'}
                                fontSize={'md'}
                                fontWeight={500}
                                color={linkColor}
                                _hover={{
                                textDecoration: 'none',
                                color: linkHoverColor,
                                }}>
                                {navItem.label}
                            </Link> */}

                            <Stack p={4}
                                w={'full'}
                                fontSize={'sm'}>
                                <Text textTransform='uppercase'
                                    letterSpacing={1}
                                    color={'gray.500'}
                                >
                                    {navItem.label}</Text>
                                {navItem.children.map((child) => (
                                    <DesktopSubNav key={child.label}
                                        {...child}
                                    />
                                ))}
                            </Stack>
                        </PopoverTrigger>

                        {navItem.children && (
                            <PopoverContent
                                border={0}
                                boxShadow={'xl'}
                                bg={popoverContentBgColor}
                                p={4}
                                rounded={'xl'}
                                minW={'sm'}>
                                {/* <Stack>
                  {navItem.children.map((child) => (
                    <DesktopSubNav key={child.label} {...child} />
                  ))}
                </Stack> */}
                            </PopoverContent>
                        )}
                    </Popover>
                </Box>
            ))}
        </Stack>
    );
};


// const MobileNav = () => {
//   return (

//     <Stack
//       p={4}
//       bg={useColorModeValue('white', 'gray.800')}
//       color={useColorModeValue('gray.800', 'white')}
//       display={{ base: 'flex', md: 'none' }}>
//       {NAV_ITEMS.map((navItem) => (
//         <MobileNavItem key={navItem.label} {...navItem} />
//       ))}

//     </Stack>

//   );
// };

const MobileNavItem = ({ label, children, href }: NavItem) => {
    const { isOpen, onToggle } = useDisclosure();
    return (
        <Stack spacing={4} onClick={children && onToggle}>
            <Flex
                py={2}
                as={Link}
                href={href ?? '#'}
                justify={'space-between'}
                align={'center'}
                _hover={{
                    textDecoration: 'none',
                }}>
                <Text
                    fontWeight={600}
                    color={useColorModeValue('gray.600', 'gray.200')}>
                    {label}
                </Text>
                {children && (
                    <Icon
                        as={ChevronDownIcon}
                        transition={'all .25s ease-in-out'}
                        transform={isOpen ? 'rotate(180deg)' : ''}
                        w={6}
                        h={6}
                    />
                )}
            </Flex>

            <Collapse in={isOpen} animateOpacity style={{ marginTop: '0!important' }}>
                <Stack
                    mt={2}
                    pl={4}
                    borderLeft={1}
                    borderStyle={'solid'}
                    borderColor={useColorModeValue('gray.200', 'gray.700')}
                    align={'start'}>
                    {children &&
                        children.map((child) => (
                            <Link key={child.label} py={2} href={child.href}>
                                {child.label}
                            </Link>
                        ))}
                </Stack>
            </Collapse>
        </Stack>
    );
};

interface NavItem {
    label: string;
    subLabel?: string;
    children?: Array<NavItem>;
    href?: string;
}

const NAV_ITEMS: Array<NavItem> = [
    {
        label: 'Features',
        children: [
            {
                label: 'eWallet',
                subLabel: 'Free to set up, no minimum balance required, no more clutter',
                href: '/#ewallet',
            },
            {
                label: 'Money transfers',
                subLabel: 'Send or request money anytime, anywhere. Earn rewards while you spend.',
                //Learn about our values. Maybe even grow your career with us
                href: '/#transfers',
            },
            {
                label: 'View expenses',
                subLabel: 'Get insights on your spending and earnings',
                //Learn about our values. Maybe even grow your career with us
                href: '/#viewexpenses',
            },
            {
                label: 'Cross-border Remittance',
                subLabel: 'Use 5D Pay to go the extra mile so you do not have to',
                href: '/#remittance',
            },
            {
                label: 'Cards',
                subLabel: 'Individual and corporate solutions to suit your needs',
                href: '/#cards',
            },
            {
                label: 'Rewards',
                subLabel: 'Spend more to earn more on rebates and savings',
                href: '/#rewards',
            },
        ],
    },
    {
        label: 'Company',
        children: [
            {
                label: 'About us',
                // subLabel: 'Call or email us to learn more and open an account',
                href: '/about',
            },
            {
                label: 'Careers',
                // subLabel: 'Learn about our values. Maybe even grow your career with us. See open roles.',
                href: '/careers',
            },
            // {
            //     label: 'FAQ',
                // subLabel: 'Call or email us to learn more and open an account',
            //     href: 'faq',
            // },
            {
                label: 'Security',
                // subLabel: 'Learn about our values. Maybe even grow your career with us. See open roles.',
                href: '/security',
            },
        ],
    },
    {
        label: 'Contact',
        children: [
            {
                label: 'support@yintran.com',
                // subLabel: 'Call or email us to learn more and open an account',
                href: 'mailto:support@yintran.com',
            },
            {
                label: 'careers@yintran.com',
                // subLabel: 'Learn about our values. Maybe even grow your career with us. See open roles.',
                href: 'mailto:careers@yintran.com',
            },
        ],
    },
    // {
    //     label: 'Legal',
    //     children: [
    //         {
    //             label: 'Terms & Conditions',
    //             // subLabel: 'Call or email us to learn more and open an account',
    //             href: './terms',
    //         },
    //         {
    //             label: 'Privacy Policy',
    //             // subLabel: 'Learn about our values. Maybe even grow your career with us. See open roles.',
    //             href: 'privacy',
    //         },
            // {
                // label: 'Data Policy',
                // subLabel: 'Learn about our values. Maybe even grow your career with us. See open roles.',
                // href: 'data',
            // },
        // ],
    // },
    //About us, careers, FAQ, security, contact
    // {
    //   label: 'Contact us',
    //   href: '#',
    // },
];


function Signupmodal() {
    const { isOpen, onOpen, onClose } = useDisclosure()
    return (
        <>
            {/* <Button onClick={onOpen}
        href={'#'}
        display={{ base: 'none', md: 'inline-flex' }}
        fontSize={'md'}
        fontWeight={500}
        bg={useColorModeValue('white', 'gray.800')}
        rounded={'3xl'}
        color={useColorModeValue('gray.900', 'white')}
        _hover={{
          transform: "scale(0.98)",
          transition: 'all .1s ease',
        }}
        _active={{
          transform: "scale(0.95)",
          transition: 'all 1s ease-in-out',
        }}
      >Sign in</Button> */}

            <Button
                display={{ base: 'none', md: 'inline-flex' }} //makes sign in disappear when mobile
                as={'a'}
                fontSize={'md'}
                fontWeight={500}
                borderWidth={1}
                borderColor={useColorModeValue('gray.900', 'white')}
                color={useColorModeValue('gray.900', 'white')}
                borderRadius='25px'
                p={3}
                variant={'link'}
                href={'/getstarted'}
                _hover={{
                    transform: "scale(0.98)",
                    color: 'white',
                    borderColor: useColorModeValue('white', 'gray.700'),
                    bgGradient: "linear(to-tr, #F82F7F,#AE2D30,#E44449,#F98B46,#FAC644,#DBE628,green.300,#01C101)"
                }}
                _active={{
                    transform: "scale(0.98)",
                    color: 'gray.500',
                }}

                bgClip="border"

            >Get started</Button>


        </>
    )
}

function Signinmodal() {
    const { isOpen, onOpen, onClose } = useDisclosure()
    return (
        <>
            <Button onClick={onOpen}
                as={'a'}
                fontSize={'sm'}
                fontWeight={400}
                // variant={'link'}
                href={'http://www.5dleadingtech.com:8080/MSOWeb/login'}
                bg={'transparent'}
                _hover={{
                    transform: "scale(0.98)",
                    transition: 'all .1s ease',
                    color: 'gray.500',
                    bg: 'gray.100'
                }}
                _active={{
                    transform: "scale(0.95)",
                    transition: 'all .1s ease',
                    color: 'gray.800',
                }}
            >Sign in</Button>

            {/* <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent flex="center">
          <ModalHeader>
          <HStack mt={{ base: 'none', md: 2 }} minW='157px'>
            <Image src={logo}
              textAlign={useBreakpointValue({ base: 'center', md: 'left' })}
              h={4} />
            <Text
              fontSize='12px'
              fontWeight='medium'
              textTransform="uppercase"
              letterSpacing={2}
              color={useColorModeValue('gray.900', 'white')}
            >5d leading tech</Text>
          </HStack>
          </ModalHeader>
          <ModalCloseButton rounded='full' />
          <ModalBody>
            <Signininput />
          </ModalBody>
          {/* <ModalFooter>
            <Button variant="ghost">Close</Button>
            <Button colorScheme="pink" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter> */}
            {/* </ModalContent> */}
            {/* </Modal> */}


        </>
    )
}

function Signupwelcome() {
    return (
        <Flex
            // minH={'100vh'}
            align={'center'}
            justify={'center'}
        // bg={useColorModeValue('white', 'gray.800')}
        >
            <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
                <Stack align={'center'}>
                    <Heading pt={4} fontSize={'4xl'} fontWeight={'light'}>Welcome</Heading>
                    {/* <Text fontSize={'lg'} color={'gray.600'}>
            to enjoy all of our cool <Link color={'blue.400'}>features</Link> ✌️
          </Text> */}
                </Stack>
                <Box
                    rounded={'lg'}
                    // bg={useColorModeValue('white', 'gray.800')}
                    // boxShadow={'lg'}
                    p={2}>
                    <Stack spacing={4}>

                        <Stack spacing={10}>
                            <chakra.p>Contact us to open an account</chakra.p>
                            <Button
                                bg={'orange.400'}
                                color={'white'}
                                _hover={{
                                    bg: 'orange.500',
                                    // bgGradient: 'linear(to-tr, orange.400, orange.500)'
                                }}>
                                Contact us
                            </Button>
                        </Stack>
                    </Stack>
                </Box>
            </Stack>
        </Flex>
    );
}

function Signininput() {
    return (
        <Flex
            // minH={'100vh'}
            align={'center'}
            justify={'center'}
        // bg={useColorModeValue('white', 'gray.800')}
        >
            <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
                <Stack align={'center'}>
                    <Heading fontSize={'4xl'} fontWeight={'light'}>Welcome Back</Heading>
                    {/* <Text fontSize={'lg'} color={'gray.600'}>
            to enjoy all of our cool <Link color={'blue.400'}>features</Link> ✌️
          </Text> */}
                </Stack>
                <Box
                    rounded={'lg'}
                    // bg={useColorModeValue('white', 'gray.800')}
                    // boxShadow={'lg'}
                    p={8}>
                    <Stack spacing={4}>
                        <FormControl id="email">
                            <FormLabel>Email address</FormLabel>
                            <Input type="email" />
                        </FormControl>
                        <FormControl id="password">
                            <FormLabel>Password</FormLabel>
                            <Input type="password" />
                        </FormControl>
                        <Stack spacing={10}>
                            <Stack
                                direction={{ base: 'column', sm: 'row' }}
                                align={'start'}
                                justify={'space-between'}>
                                <Checkbox>Remember me</Checkbox>
                                <Link color={'blue.300'}>Forgot password?</Link>
                            </Stack>
                            <Button
                                bg={'orange.400'}
                                color={'white'}
                                _hover={{
                                    bg: 'orange.500',
                                    // bgGradient: 'linear(to-tr, orange.400, orange.500)'
                                }}>
                                Sign in
                            </Button>
                        </Stack>
                    </Stack>
                </Box>
            </Stack>
        </Flex>
    );
}