import React from 'react';
import {
  ChakraProvider,
  Box,
  Text,
  VStack,
  theme,
  Flex,
  useBreakpointValue,
  Stack,
  Button,
  Container,
  Heading,
  Image,
  Link,
  Icon,
  List,
  ListItem,
  ListIcon,
  useColorModeValue,
  Center,
  Spacer,
  extendTheme,
  mode,
} from '@chakra-ui/react';

import { FaChevronRight, FaCheck, FaAppStore } from 'react-icons/fa';
import { ArrowForwardIcon } from '@chakra-ui/icons'

import fps from './fps_en.svg';
import Navbar from './navbar';
import Footer from './components/footer';

import Summary from './summary';
import Asseenon from './asseenon';

import transfer from './assets/graphics-features/transfer.png';
import onlineoffline from './assets/graphics-features/onlineoffline.png';
import checkspending from './assets/graphics-features/checkspending.png';
import cards from './assets/graphics-features/cards.png';
import global from './assets/graphics-features/global.png';
import rewards from './assets/graphics-features/rewards.png';

import AppStoreGroup from './components/AppStoreGroup';
import MobileBottomAppStoreGroup from './components/MobileBottomAppStoreGroup';

// import ReactDOM from 'react-dom';
// import MessengerCustomerChat from 'react-messenger-customer-chat';

import MessengerCustomerChat from './components/MessengerCustomerChat';

function App() {
  return (
    <ChakraProvider theme={theme}>
      <div>
        <MessengerCustomerChat
          pageId="104135944511753"
          appId="588938452310317"
        />
      </div>
      <Navbar />
      <Container maxW={'6xl'}>
        <Box
        // pt={16}
        >
          <LandingHero />
        </Box>
        <Box
          mt={{ base: 2, md: '160px' }}
        >
          <Summary />
        </Box>
        <Descriptions />


      </Container>
      <Box h={'80px'} bg='white' color='white' />
      <Asseenon />
      <Box h={'100px'} bg='white' color='white' />

      <Footer />



    </ChakraProvider>
  );
}

export default App;

function LandingHero() {
  return (
    <Container maxW={'6xl'}
      w={'full'}
    // h={'100vh'}
    >
      <Stack
        align={'center'}
        spacing={{ base: 8, md: 10 }}
        pt={{ base: 20, md: 28 }}
        direction={{ base: 'column', md: 'row' }}
      >
        {/* <Center> */}
        <Stack flex={1} spacing={{ base: 5, md: 10 }}>
          <Heading
            textAlign={{ base: 'center', md: 'left' }}
            lineHeight={1.0}
            fontWeight={300}
            fontSize={{ base: '7xl', md: '8xl' }}
          // letterSpacing={2}
          >
            <Text as={'span'}>
              Make spending its own <br />
            </Text>

            <Text as={'span'}
              bgGradient="linear(to-tr, #F82F7F,#AE2D30,#E44449,#F98B46,#FAC644,#DBE628,green.300,#01C101)"
              bgClip="text"
              position={'relative'}
            >
              Reward
            </Text>
          </Heading>

          <Text
            py={{ base: 4, md: 1 }}
            // pr={{ base: 'none', md: '56px' }}
            as={'span'}
            color={useColorModeValue('gray.500', 'gray.200')}
            fontWeight='normal'
            fontSize={{ base: 'lg', md: 'xl' }} >
            It will always be free to set up an eWallet. Get started in minutes. We'll walk you through it when you download the app.
          </Text>

          <Flex display={{ base: 'none', md: 'flex' }}>
            <div id='getstarted'>
              <AppStoreGroup />
            </div>
          </Flex>
        </Stack>

        <MobileBottomAppStoreGroup />

        <Flex
          flex={1}
          justify={'center'}
          align={'center'}
          position={'relative'}
          w={'full'}>
          <Box
            position={'relative'}
            // height={'560px'}
            height={{ base: '364px', md: '560px' }}
            width={'full'}
            overflow={'hidden'}
          // mb={100}
          >
            <Image
              alt={'Phone and card with QR for download'}
              fit={'cover'}
              align={'center'}
              w={{ base: 'auto', md: '100%' }}
              h={{ base: 'auto', md: '100%' }}
              src={'svg/card-phone.png'}
            />
          </Box>
        </Flex>
        {/* </Center> */}
      </Stack>
    </Container>
  );
}

function Descriptions(props: DescriptionItem) {
  return (
    <Container maxW={'6xl'}>
      {/* Feature #1*/}
      <div id='transfers'>
        <Stack
          align={'center'}
          spacing={{ base: 8, md: 10 }}
          pt={{ base: 20, md: 28 }}
          direction={{ base: 'column-reverse', md: 'row' }}>

          <Stack flex={1} spacing={{ base: 5, md: 10 }}>
            <Heading
              lineHeight={1}
              fontWeight={400}
              fontSize={{ base: '4xl', sm: '5xl', lg: '6xl' }}>
              <Text
                as={'span'}
                position={'relative'}
              >
                Transfer money to others,
                free of charge
              </Text>
            </Heading>
            <Text
              color={useColorModeValue('gray.500', 'gray.200')}>
              <List fontSize='lg' pt={1} pb={12}>
                <ListItem pb={2}>
                  <ListIcon color={'pink.300'} as={FaCheck} />
                  Transfer money to anyone on or even off the platform and use 5D Pay continously with services you already use.
                </ListItem>
                <ListItem pb={2}>
                  <ListIcon color={'pink.300'} as={FaCheck} />
                  It's free to create an account, and it only takes minutes.
                </ListItem>
                <ListItem pb={2}>
                  <ListIcon color={'pink.300'} as={FaCheck} />
                  Straightforward layout of functions you need so you don't have to fumble around for buttons when you're in a hurry.
                </ListItem>
                <ListItem pb={2}>
                  <ListIcon color={'pink.300'} as={FaCheck} />
                  In a crowded place? No fear, your balance will be discretely shown so you can take care of your money in public worry-free.
                </ListItem>
              </List>
            </Text>
            <Stack
              spacing={{ base: 4, sm: 6 }}
              direction={{ base: 'column', sm: 'row' }}>
              <Link href={'/getstarted'}>
                <Button
                  // rounded={'full'}

                  variant={'link'}
                  size={'lg'}
                  fontWeight={'normal'}
                  px={6}
                  color='blue.300'
                  bg={'transparent'}
                  _hover={{
                    bg: 'transparent',
                    transform: "scale(0.98)",
                    transition: 'all .1s ease',
                  }}
                  _active={{
                    bg: 'transparent',
                    transform: "scale(0.95)",
                    transition: 'all .1s ease',
                    color: 'blue.500'
                  }}
                >
                  Try it now
                  <Icon ml={1} as={ArrowForwardIcon} />
                </Button>
              </Link>
            </Stack>
          </Stack>
          <Flex
            flex={1}
            justify={'center'}
            align={'center'}
            position={'relative'}
            w={'full'}>
            <Box
              position={'relative'}
              height={{ base: '364px', md: '664px' }}
              // rounded={'3xl'}
              width={'572px'}
              overflow={'hidden'}>
              <Image
                alt={'Transfer'}
                fit={'cover'}
                align={'center'}
                // w={'100%'}
                // h={'100%'}
                w={{ base: 'auto', md: '100%' }}
                h={{ base: 'auto', md: '100%' }}
                src={transfer}
              />
            </Box>
          </Flex>
        </Stack>
      </div>


      {/* Feature #2*/}
      <div id='ewallet'>
        <Stack
          align={'center'}
          spacing={{ base: 8, md: 10 }}
          pt={{ base: 20, md: 28 }}
          direction={{ base: 'column-reverse', md: 'row-reverse' }}>
          <Stack flex={1} spacing={{ base: 5, md: 10 }}>
            <Heading
              lineHeight={1}
              fontWeight={400}
              fontSize={{ base: '4xl', sm: '5xl', lg: '6xl' }}>
              <Text
                as={'span'}
                position={'relative'}
              >
                Send and request money online and offline
              </Text>
            </Heading>
            <Text
              color={useColorModeValue('gray.500', 'gray.200')}>
              <List fontSize='lg' pt={1} pb={12}>
                <ListItem pb={2}>
                  <ListIcon color={'pink.300'} as={FaCheck} />
                  Skip the lines; scan to pay or send money at a distance
                </ListItem>
                <ListItem pb={2}>
                  <ListIcon color={'pink.300'} as={FaCheck} />
                  Spend at your favorite shops on - and even off - the platform¹
                </ListItem>
                <ListItem pb={2}>
                  <ListIcon color={'pink.300'} as={FaCheck} />
                  Donate to your favourite charities
                </ListItem>
                <ListItem pb={2}>
                  <ListIcon color={'pink.300'} as={FaCheck} />
                  Collect membership dues in advance
                </ListItem>
              </List>
            </Text>
            <Stack
              spacing={{ base: 4, sm: 6 }}
              direction={{ base: 'column', sm: 'row' }}>
                <Link href={'/getstarted'}>
              <Button
                // rounded={'full'}
                href={'/getstarted'}
                variant={'link'}
                size={'lg'}
                fontWeight={'normal'}
                px={6}
                color='blue.300'
                bg={'transparent'}
                _hover={{
                  bg: 'transparent',
                  transform: "scale(0.98)",
                  transition: 'all .1s ease',
                }}
                _active={{
                  bg: 'transparent',
                  transform: "scale(0.95)",
                  transition: 'all .1s ease',
                  color: 'blue.500'
                }}
              >
                Try it now
                <Icon ml={1} as={ArrowForwardIcon} />
              </Button>
              </Link>
            </Stack>
          </Stack>
          <Flex
            flex={1}
            justify={'center'}
            align={'center'}
            position={'relative'}
            w={'full'}>
            <Box
              position={'relative'}
              height={{ base: '364px', md: '664px' }}
              // rounded={'3xl'}

              width={'572px'}
              overflow={'hidden'}>
              <Image
                alt={'transfer online and offline'}
                fit={'cover'}
                align={'center'}
                // w={'100%'}
                h={'100%'}
                w={{ base: 'auto', md: '100%' }}
                // h={{ base: 'auto', md: '100%' }}
                src={onlineoffline}
              />
            </Box>
          </Flex>
        </Stack>
      </div>

      {/* Feature #3*/}
      <div id='viewexpenses'>
        <Stack
          align={'center'}
          spacing={{ base: 8, md: 10 }}
          pt={{ base: 20, md: 28 }}
          direction={{ base: 'column-reverse', md: 'row' }}>
          <Stack flex={1} spacing={{ base: 5, md: 10 }}>
            <Heading
              lineHeight={1}
              fontWeight={400}
              fontSize={{ base: '4xl', sm: '5xl', lg: '6xl' }}>
              <Text
                as={'span'}
                position={'relative'}
              >
                View expenses and get notified of incoming funds
              </Text>
            </Heading>
            <Text
              color={useColorModeValue('gray.500', 'gray.200')}>
              <List fontSize='lg' pt={1} pb={12}>
                <ListItem pb={2}>
                  <ListIcon color={'pink.300'} as={FaCheck} />
                  View your account summary at your fingertips
                </ListItem>
                <ListItem pb={2}>
                  <ListIcon color={'pink.300'} as={FaCheck} />
                  Get notified when you have incoming funds
                </ListItem>
                <ListItem pb={2}>
                  <ListIcon color={'pink.300'} as={FaCheck} />
                  Make it easy to stay on top of your earnings and spending
                </ListItem>
              </List>
            </Text>
            <Stack
              spacing={{ base: 4, sm: 6 }}
              direction={{ base: 'column', sm: 'row' }}>
                <Link href={'/getstarted'}>
              <Button
                // rounded={'full'}
                href={'/getstarted'}
                variant={'link'}
                size={'lg'}
                fontWeight={'normal'}
                px={6}
                color='blue.300'
                bg={'transparent'}
                _hover={{
                  bg: 'transparent',
                  transform: "scale(0.98)",
                  transition: 'all .1s ease',
                }}
                _active={{
                  bg: 'transparent',
                  transform: "scale(0.95)",
                  transition: 'all .1s ease',
                  color: 'blue.500'
                }}
              >
                Try it now
                <Icon ml={1} as={ArrowForwardIcon} />
              </Button>
              </Link>
            </Stack>
          </Stack>
          <Flex
            flex={1}
            justify={'center'}
            align={'center'}
            position={'relative'}
            w={'full'}>
            <Box
              position={'relative'}
              height={{ base: '364px', md: '664px' }}
              rounded={'3xl'}

              width={'572px'}
              overflow={'hidden'}>
              <Image
                alt={'Transfer'}
                fit={'cover'}
                align={'center'}
                w={'100%'}
                h={'100%'}
                src={checkspending}
              />
            </Box>
          </Flex>
        </Stack>
      </div>

      {/* Feature #4*/}
      <div id='cards'>
        <Stack
          align={'center'}
          spacing={{ base: 8, md: 10 }}
          pt={{ base: 20, md: 28 }}
          direction={{ base: 'column-reverse', md: 'row-reverse' }}>
          <Stack flex={1} spacing={{ base: 5, md: 10 }}>
            <Heading
              lineHeight={1}
              fontWeight={400}
              fontSize={{ base: '4xl', sm: '5xl', lg: '6xl' }}>
              <Text
                as={'span'}
                position={'relative'}
              >
                5D Pay Cards
              </Text>
            </Heading>
            <Text
              color={useColorModeValue('gray.500', 'gray.200')}>
              <List fontSize='lg' pt={1} pb={12}>
                <ListItem pb={2}>
                  <ListIcon color={'pink.300'} as={FaCheck} />
                  Instant approval for 5D Pay cards with terms
                </ListItem>
                <ListItem pb={2}>
                  <ListIcon color={'pink.300'} as={FaCheck} />
                  Access merchants online, offline - locally, and globally
                </ListItem>
                <ListItem pb={2}>
                  <ListIcon color={'pink.300'} as={FaCheck} />
                  Use it on public transport in Hong Kong
                </ListItem>
                <ListItem pb={2}>
                  <ListIcon color={'pink.300'} as={FaCheck} />
                  Have spending deducted directly from 5D Pay eWallet
                </ListItem>
                <ListItem pb={2}>
                  <ListIcon color={'pink.300'} as={FaCheck} />
                  Instantly customize your card amount and reloading frequency
                </ListItem>
                <ListItem pb={2}>
                  <ListIcon color={'pink.300'} as={FaCheck} />
                  Disable your card instantly if lost or stolen
                </ListItem>
                <ListItem pb={2}>
                  <ListIcon color={'pink.300'} as={FaCheck} />
                  Personalize it with any of our offerrings or your own corporate branding
                </ListItem>
              </List>
            </Text>
            <Stack
              spacing={{ base: 4, sm: 6 }}
              direction={{ base: 'column', sm: 'row' }}>
                <Link href={'/getstarted'}>
              <Button
                // rounded={'full'}
                variant={'link'}
                size={'lg'}
                fontWeight={'normal'}
                px={6}
                color='blue.300'
                bg={'transparent'}
                _hover={{
                  bg: 'transparent',
                  transform: "scale(0.98)",
                  transition: 'all .1s ease',
                }}
                _active={{
                  bg: 'transparent',
                  transform: "scale(0.95)",
                  transition: 'all .1s ease',
                  color: 'blue.500'
                }}
              >
                Learn more
                <Icon ml={1} as={ArrowForwardIcon} />
              </Button>
              </Link>
            </Stack>
          </Stack>
          <Flex
            flex={1}
            justify={'center'}
            align={'center'}
            position={'relative'}
            w={'full'}>
            <Box
              position={'relative'}
              height={{ base: '364px', md: '664px' }}
              rounded={'3xl'}
              // boxShadow={'2xl'}
              width={'572px'}
              overflow={'hidden'}>
              <Image
                alt={'Transfer'}
                fit={'cover'}
                align={'center'}
                w={'100%'}
                h={'100%'}
                src={cards}
              />
            </Box>
          </Flex>
        </Stack>
      </div>

      {/* Feature #5*/}
      <div id='remittance'>
        <Stack
          align={'center'}
          spacing={{ base: 8, md: 10 }}
          pt={{ base: 20, md: 28 }}
          direction={{ base: 'column-reverse', md: 'row' }}>
          <Stack flex={1} spacing={{ base: 5, md: 10 }}>
            <Heading
              lineHeight={1}
              fontWeight={400}
              fontSize={{ base: '4xl', sm: '5xl', lg: '6xl' }}>
              <Text
                as={'span'}
                position={'relative'}
              >
                Send globally as you would locally
              </Text>
            </Heading>
            <Text
              color={useColorModeValue('gray.500', 'gray.200')}>
              <List fontSize='lg' pt={1} pb={12}>
                <ListItem pb={2}>
                  <ListIcon color={'pink.300'} as={FaCheck} />
                  Wanderlust? Travel for business? Do a ton of online shopping? Spend like a local in supported currencies
                </ListItem>
                <ListItem pb={2}>
                  <ListIcon color={'pink.300'} as={FaCheck} />
                  Send money internationally in supported countries
                </ListItem>
              </List>
            </Text>
            <Stack
              spacing={{ base: 4, sm: 6 }}
              direction={{ base: 'column', sm: 'row' }}>
                <Link href={'/getstarted'}>
              <Button
                // rounded={'full'}
                href={'/getstarted'}
                variant={'link'}
                size={'lg'}
                fontWeight={'normal'}
                px={6}
                color='blue.300'
                bg={'transparent'}
                _hover={{
                  bg: 'transparent',
                  transform: "scale(0.98)",
                  transition: 'all .1s ease',
                }}
                _active={{
                  bg: 'transparent',
                  transform: "scale(0.95)",
                  transition: 'all .1s ease',
                  color: 'blue.500'
                }}
              >
                Try it now
                <Icon ml={1} as={ArrowForwardIcon} />
              </Button>
              </Link>
            </Stack>
          </Stack>
          <Flex
            flex={1}
            justify={'center'}
            align={'center'}
            position={'relative'}
            w={'full'}>
            <Box
              position={'relative'}
              height={{ base: '364px', md: '664px' }}
              rounded={'3xl'}
              // boxShadow={'2xl'}
              width={'572px'}
              overflow={'hidden'}>
              <Image
                alt={'Transfer'}
                fit={'cover'}
                align={'center'}
                w={'100%'}
                h={'100%'}
                src={global}
              />
            </Box>
          </Flex>
        </Stack>
      </div>

      {/* Feature #6*/}
      <div id='rewards'>
        <Stack
          align={'center'}
          spacing={{ base: 8, md: 10 }}
          pt={{ base: 20, md: 28 }}
          direction={{ base: 'column-reverse', md: 'row-reverse' }}>
          <Stack flex={1} spacing={{ base: 5, md: 10 }}>
            <Heading
              lineHeight={1}
              fontWeight={400}
              fontSize={{ base: '4xl', sm: '5xl', lg: '6xl' }}>
              <Text
                as={'span'}
                position={'relative'}
              >
                Earn rewards
              </Text>
            </Heading>
            <Text
              color={useColorModeValue('gray.500', 'gray.200')}>
              <List fontSize='lg' pt={1} pb={12}>
                <ListItem pb={2}>
                  <ListIcon color={'pink.300'} as={FaCheck} />
                  Spend more to earn more - whether that’s from your eWallet, cards, or subaccounts
                </ListItem>
                <ListItem pb={2}>
                  <ListIcon color={'pink.300'} as={FaCheck} />
                  Earn rewards even just by keeping a balance
                </ListItem>
              </List>
            </Text>
            <Stack
              spacing={{ base: 4, sm: 6 }}
              direction={{ base: 'column', sm: 'row' }}>
              <Link href={'/getstarted'}>
                <Button
                  pb={{ base: 12, md: 'none' }}
                  // rounded={'full'}
                  variant={'link'}
                  size={'lg'}
                  fontWeight={'normal'}
                  px={6}
                  color='blue.300'
                  bg={'transparent'}
                  _hover={{
                    bg: 'transparent',
                    transform: "scale(0.98)",
                    transition: 'all .1s ease',
                  }}
                  _active={{
                    bg: 'transparent',
                    transform: "scale(0.95)",
                    transition: 'all .1s ease',
                    color: 'blue.500'
                  }}
                >
                  Start earning today
                  <Icon ml={1} as={ArrowForwardIcon} />
                </Button>
              </Link>
            </Stack>
          </Stack>
          <Flex
            flex={1}
            justify={'center'}
            align={'center'}
            position={'relative'}
            w={'full'}>
            <Box
              position={'relative'}
              height={{ base: '364px', md: '664px' }}
              rounded={'3xl'}
              // boxShadow={'2xl'}
              width={'572px'}
              overflow={'hidden'}>
              <Image
                alt={'Transfer'}
                fit={'cover'}
                align={'center'}
                w={'100%'}
                h={'100%'}
                src={rewards}
              />
            </Box>
          </Flex>
        </Stack>
      </div>


    </Container>
  );
}

