import {
  Box,
  Link,
  Container,
  SimpleGrid,
  Stack,
  HStack,
  Text,
  useBreakpointValue,
  Image
} from '@chakra-ui/react';
import { ReactNode } from 'react';
import logo from '../assets/logoR.png';

const ListHeader = ({ children }: { children: ReactNode }) => {
  return (
    <Text fontWeight={'500'} fontSize={'lg'} mb={2}>
      {children}
    </Text>
  );
};

export default function LargeWithNewsletter() {
  return (
    <Box
    bg='white'
    color='gray.900'
      // bg={useColorModeValue('white', 'gray.800')}
      // color={useColorModeValue('gray.800', 'gray.400')}
      >
      <Container as={Stack} maxW={'6xl'} py={10}>
        <SimpleGrid
          templateColumns={{ sm: '1fr 1fr', md: '1.5fr 1.5fr 1fr 1fr 1fr 1.5fr' }}
          spacing={8}>
          <Stack spacing={6}>
            <Box>
              <HStack>
              <Link href="/" > <Image src={logo}
                  textAlign={useBreakpointValue({ base: 'center', md: 'left' })}
                  h={8} /></Link>
              </HStack>
            </Box>
            <Text fontSize={'sm'}>
              © 2021 5D Pay powered by Yintran Group Holdings Limited (SVF0014). <br/>All rights reserved.
            </Text>
          </Stack>
          <Stack align={'flex-start'}>
            <ListHeader>Features</ListHeader>
            <Link href={'/#ewallet'}>eWallet</Link>
            <Link href={'/#transfers'}>Money transfers</Link>
            <Link href={'/#viewexpenses'}>View expenses</Link>
            <Link href={'/#remittance'}>Cross-border remittance</Link>
            <Link href={'/#cards'}>Cards</Link>
            <Link href={'/#rewards'}>Rewards</Link>
          </Stack>
          <Stack align={'flex-start'}>
            <ListHeader>Company</ListHeader>
            <Link href={'/about'}>About us</Link>
            <Link href={'/careers'}>Careers</Link>
            {/* <Link href={'#'}>FAQ</Link> */}
            <Link href={'/security'}>Security</Link>
          </Stack>
          <Stack align={'flex-start'}>
            <ListHeader>Follow us</ListHeader>
            <Link href={'https://www.linkedin.com/company/yintran'}>LinkedIn</Link>
            <Link href={'https://www.facebook.com/5Dpayofficial/'}>Facebook</Link>
          </Stack>
          <Stack align={'flex-start'}>
            <ListHeader>Contact</ListHeader>
            <Link href={'mailto:support@yintran.com'}>support@yintran.com</Link>
            <Link href={'mailto:careers@yintran.com'}>careers@yintran.com</Link>
          </Stack>
          <Stack align={'flex-start'}>
            <ListHeader>Legal</ListHeader>
            <Link href={'/terms'}>Terms {'&'} Conditions</Link>
            <Link href={'/privacy'}>Privacy Policy</Link>
            {/* <Link href={'data'}>Data Policy</Link> */}
          </Stack>
          {/* <Stack align={'flex-start'}>
            <ListHeader>Let us share new products and features with you</ListHeader>
            <Stack direction={'row'}>
              <Input
                placeholder={'Your email address'}
                bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.200')}
                border={0}
                _focus={{
                  bg: 'whiteAlpha.300',
                }}
              />
              <IconButton
                // bg={useColorModeValue('orange.400', 'orange.800')}
                bgGradient={'linear(to-tr, #D82E5D 5%, #F98B46, #F9CE30)'}
                color={useColorModeValue('white', 'gray.800')}
                // _hover={{
                //   bg: 'pink.300',
                // }}
                _hover={{
                  bg: 'orange.300',
                  transform: "scale(0.98)",
                  transition: 'all .1s ease',
                }}
                _active={{
                  bg: 'orange.500',
                  transform: "scale(0.95)",
                  transition: 'all .1s ease',
                }}
                aria-label="Subscribe"
                icon={<FaChevronRight />}
              />
            </Stack>
          </Stack> */}
        </SimpleGrid>
      </Container>
      <Box h={20}></Box>
    </Box>
  );
}