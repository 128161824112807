import {
  Box,
  Text,
  Flex,
  VStack,
  Image,
  Center, Icon, Divider} from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons'

import Navbar from '../navbar';
import Footer from '../components/footer';

export default function security(){
    return (
    <Box>
        <Navbar />
        <Security />
        <Footer />
    </Box>
    )
}

const Security = () =>{
    return (
        <Center paddingBottom={{base:"35", md:"70"}}>

            <Box maxW="904px" px={{base:8, md:16}} pb={{base:20, md:40}}>
            {/* Terms and Conditions - last updated... */}
            <VStack>
                <Text 
                    pt={{base:40, md:60}} 
                    pb="80px" 
                    fontSize={{base:"30px" , md:"64px"}}
                    align="center"
                    fontWeight="medium">
                    Cross-boarder Remittance
                </Text>
               
            </VStack>

            <Box>
                {/* SECURITY FEATURE #1 */}
                <Box pt="16px" pb="8px"> 
                    <Flex>
                    <Box paddingRight="12px" paddingBottom="6px"><CheckIcon color="#BEBEBE"/></Box>
                    <Text fontSize="16px">
                     Make worldwide payments with no hidden fees
                    </Text>
                    </Flex>
                </Box>


                {/* SECURITY FEATURE #2 */}
                <Box pt="16px" pb="8px"> 
                    <Flex>
                    <Box paddingRight="12px" paddingBottom="6px"><CheckIcon color="#BEBEBE"/></Box>
                    <Text fontSize="16px">
                    An easier way to transfer money globally
                    </Text>
                    </Flex>
                </Box>

              
            </Box>

            </Box>
        </Center>


    )
}
