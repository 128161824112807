import {
  Box,
  Text,
  Flex,
  VStack,
  Image,
  Center, Icon, Divider} from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons'

import Navbar from '../navbar';
import Footer from '../components/footer';

export default function security(){
    return (
    <Box>
        <Navbar />
        <Security />
        <Footer />
    </Box>
    )
}

const Security = () =>{
    return (
        <Center paddingBottom={{base:"35", md:"70"}}>

            <Box maxW="904px" px={{base:8, md:16}} pb={{base:20, md:40}}>
            {/* Terms and Conditions - last updated... */}
            <VStack>
                <Text 
                    pt={{base:40, md:60}} 
                    pb="80px" 
                    fontSize={{base:"36px" , md:"64px"}}
                    align="center"
                    fontWeight="normal">
                    Security
                </Text>
                {/* <Text paddingTop="98px" paddingBottom="32px" fontStyle="italic">
                    We want your experiences to be safe.
                </Text> */}
            </VStack>
               
            {/* PREFACE*/}
            {/* <Text pt="32px" pb="16px" fontWeight="medium" fontSize="16px">
                    Preface
                </Text> */}
            <Box>
                {/* SECURITY FEATURE #1 */}
                <Box pt="16px" pb="8px"> 
                    <Flex>
                    <Box paddingRight="12px" paddingBottom="6px"><CheckIcon color="pink.300"/></Box>
                    <Text fontSize="xl">
                    5D Pay eWallet has the highest level available secured data encryption to protect against unauthorized payment transfer and prevent unauthorized access to the personal information.
                    </Text>
                    </Flex>
                </Box>

                {/* SECURITY FEATURE #2 */}
                {/* <Box pt="16px" pb="8px"> 
                    <Flex>
                    <Box paddingRight="12px" paddingBottom="6px"><CheckIcon color="#BEBEBE"/></Box>
                    <Text fontSize="16px">
                    5D Pay UPI card is certified with the Payment Card Industry Data Security Standard (PCI DSS).
                    </Text>
                    </Flex>
                </Box> */}

                {/* PCI DSS Compliant image */}
                {/* <Center>
                    <Box padding="32px">
                        <Box boxSize={{base:"180px", md:"280px"}} h={{base:"80px", md:"108px"}}>
                            <Image src="pci-dss-compliant.svg" alt="PCI DSS Compliant"></Image>
                        </Box>
                    </Box>
                </Center> */}

                {/* SECURITY FEATURE #3 */}
                <Box pt="16px" pb="8px"> 
                    <Flex>
                    <Box paddingRight="12px" paddingBottom="6px"><CheckIcon color="pink.300"/></Box>
                    <Text fontSize="xl">
                    We perform regular penetration tests to find and address any security vulnerability.
                    </Text>
                    </Flex>
                </Box>
                
                {/* SECURITY FEATURE #4 */}
                <Box pt="16px" pb="8px"> 
                    <Flex>
                    <Box paddingRight="12px" paddingBottom="6px"><CheckIcon color="pink.300"/></Box>
                    <Text fontSize="xl">
                    We have built tools to detect and prevent potential frauds.  All transactions are tracked in real time, such that you may instantly block a transaction for your 5D Pay eWallet or UPI card if you suspect an unauthorized use.
                    </Text>
                    </Flex>
                </Box>





            </Box>

            </Box>
        </Center>


    )
}