import { ReactElement } from 'react';
import {
    Box, SimpleGrid, Icon, Text, Stack, Flex, useColorModeValue, Image,
    Center,
} from '@chakra-ui/react';
import { FcAssistant, FcDonate, FcInTransit } from 'react-icons/fc';


import cu from '../assets/logo-schools/logo-cu2.png'
import cuhk from '../assets/logo-schools/logo-cuhk.png'
import mit from '../assets/logo-schools/logo-mit.svg'
import penn from '../assets/logo-schools/logo-penn.png'
import stanford from '../assets/logo-schools/logo-stanford.png'
import ucla from '../assets/logo-schools/logo-ucla.svg'
import uot from '../assets/logo-schools/logo-uot.png'
import wam from '../assets/logo-schools/logo-wam.png'

interface FeatureProps {
    title: string;
    text: string;
    icon: ReactElement;
}

const Feature = ({ title, text, icon }: FeatureProps) => {
    return (
        <Center>
            <Flex
                // w={16}
                // h={16}
                // align={'center'}
                // justify={'center'}
                // color={'white'}
                // rounded={'full'}
                // bg={useColorModeValue('white', 'gray.800')}
                mb={1}
                >
                {icon}
            </Flex>
        </Center>
    );
};

export default function SchoolsSimpleFourColumns() {
    return (
        <Box

        px={{base: 4, md:'none'}}
        pb={4}
        >

            <Box
                // bg={useColorModeValue('white', 'white')}
            >

                <Center>
                    <SimpleGrid columns={{ base: 3, md: 4 }} spacing={{ base: 6, md: 10 }} 
                        alignContent='center'
                        px={{ base: 4, md: 0 }}
                        w={'full'}
                        maxW={'6xl'}
                    >
                        <Feature icon={<Image src={stanford} 
                        w={40} 
                        h={'full'}
                         />} />
                        <Feature icon={<Image src={mit} 
                        w={'full'}
                        h={10} 
                        />} />
                        <Feature icon={<Image src={penn} 
                        w={40} 
                        h={'full'}
                        />} />
                        <Feature icon={<Image src={ucla} 
                        w={80} 
                        h={12} 
                        />} />

                        <Feature icon={<Image src={uot} 
                        w={'full'}
                        h={{base:14,md:14}}
                         />} />
                        
                        <Feature icon={<Image src={wam} 
                        w={80} 
                        h={'full'}
                        // h={{base:10,md:12}}
                         />} />
                        <Feature icon={<Image src={cuhk} 
                        w={'full'}
                        h={{base:10 ,md:6}}
                        />} />
                        <Feature icon={<Image src={cu} 
                        w={'full'}
                        h={{base:14,md:14}}
                        />} />

                    

                    </SimpleGrid>
                </Center>
            </Box>
        </Box>
    );
}